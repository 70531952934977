import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';


const HelpPage = () => {

   return (
    <>
    <Helmet>
        <title>Atlantis | Help </title>
      </Helmet>
      <Header/>
      <section className="px-5 py-10">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-sm-start text-center">
                    <h1 className="display-5 fw-bold mb-4">Help</h1>
                    <h2 className="display-6 fw-bold mb-3">Web server usage</h2>
                     <p className="fs-1 mb-5 text-justify mb-2">
                      The Atlantis database integrates diverse layers of
                      structural and functional annotations for each residue to help understand the functional
                      significance of specific residues within protein structures, complexes, and interaction networks.
                      Thus, it provides a thorough comprehension of protein functionality.
                      </p>
                     <img tabindex="1" src="/assets/images/Atlantis.png" className="center-help shrink img-fluid mb-5"
                     data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                     <p className="fs-1 text-justify mb-5">
                     On our web server's main page, users can conduct queries based on gene symbols, UniProt accession numbers,
                     UniProt accession numbers combined with residue positions in the protein sequence, or gene symbols along
                     with residue positions in the protein sequence. The latter two options offer annotations specific to the
                     queried residue.
                     </p>
                     <h3 className="display-6 fw-bold mb-4">Results Page</h3>
                     <h5 className="display-6 fw-bold mb-3">Functional site information</h5>
                     <p className="fs-1 text-justify mb-5">
                        This section provides various types of annotations at the residue level, including UniProt annotations such
                        as variants, molecule processing, membrane localization, motifs, domains, glycosylations, lipidations,
                        modified residues, disulfide bonds, and secondary structures. Protein architecture information is provided
                        through PFAM and InterPro domains and aligned positions in the Hidden Markov Model (HMM). Additionally,
                        it includes information on IntAct binding regions and protein post-translational modifications
                        sourced from PhosphoSitePlus.
                    </p>
                     <img tabIndex="1" src="/assets/images/results_page.png" className="center-help shrink img-fluid mb-5"
                     data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>

                     <img tabindex="1" src="/assets/images/tip.png" className="center-help shrink img-fluid mb-1"
                     data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>

                    <p className="fs-1 text-justify mb-2"> The functional annotation for each residue is presented in a table with 23 columns:</p>
                    <ul className="mb-5">
                    <li className="fs-1 text-justify">The 'Position ' column indicates the residue position in the protein sequence.</li>
                    <li className="fs-1 text-justify">The 'Ref' column contains the UniProt reference proteome residue.</li>
                    <li className="fs-1 text-justify">The 'afRes' column contains the predicted AlphaFold residue.</li>
                    <li className="fs-1 text-justify">The 'Natural Variants' column contains the UniProt annotated natural variants.</li>
                    <li className="fs-1 text-justify">The 'Molecule Processing' column contains the UniProt annotated molecule processing (signal-peptide/transit-peptide/pro-peptide/chain).</li>
                    <li className="fs-1 text-justify">The 'Location ' column contains the UniProt localization with respect to membranes.</li>
                    <li className="fs-1 text-justify">The 'Motives ' column contains the UniProt annotated motives.</li>
                    <li className="fs-1 text-justify">The 'Domains ' column contains the UniProt annotated domains. </li>
                    <li className="fs-1 text-justify">The 'PFAM-Domain HMM Pos' column contains the PFAM domain and aligned position in the HMM.</li>
                    <li className="fs-1 text-justify">The 'Binding Site' column contains the UniProt annotated ligands (binding site).</li>
                    <li className="fs-1 text-justify">The 'Active Site' column contains the UniProt annotated properties (active site).</li>
                    <li className="fs-1 text-justify">The 'PTMs' column contains the list of annotated Phosphosite PTMs.</li>
                    <li className="fs-1 text-justify">The 'Glycosylations' column contains the UniProt annotated glycosylations.</li>
                    <li className="fs-1 text-justify">The 'Lipidations' column contains the UniProt annotated lipidations.</li>
                    <li className="fs-1 text-justify">The 'Modified Residues' column contains the UniProt annotated modified residues.</li>
                    <li className="fs-1 text-justify">The 'Disulfide bong bridges' column contains the UniProt annotated disulphide-bond bridges.</li>
                    <li className="fs-1 text-justify">The 'Secondary Structure' column contains the UniProt annotated secondary structure.</li>
                    <li className="fs-1 text-justify">The 'AF-Predicted Secondary Structure' column contains the AlphaFold predicted secondary structure.</li>
                    <li className="fs-1 text-justify">The 'AF-Confidence Value' column contains the AlphaFold confidence value.</li>
                    <li className="fs-1 text-justify">The 'AF-Max Predicted IntRes' column contains the AlphaFold maximum predicted number of interacting residues in a single structure (r = 8A). </li>
                    <li className="fs-1 text-justify">The 'AF-Predicted residues' column contains the AlphaFold list of residues with a predicted interaction in at least one structure (r = 8A).</li>
                    <li className="fs-1 text-justify">The 'CrossLinks' column contains the UniProt annotated crosslinks.</li>
                    <li className="fs-1 text-justify">The 'Binding Region' column contains the Binding region from IntAct.
                    The data is structured as follows: UniProt accession number of interactor A (binding region range),
                    UniProt accession number of interactor B (binding region range), interaction type, and MI score.
                    For instance: O00459(291-306)_P46108(124-198)_direct interaction(MI SCORE:0.4).
                    </li>
                    </ul>
                     <p className="fs-1 text-justify mb-5">
                     Furthermore, users can visualize the 3D structure and the sequence with various annotations mapping by selecting
                     the respective elements. This feature lets users gain insight into the contextual information surrounding functional
                     site information. Additionally, users can modify the Mol* viewer settings to suit their preferences.
                     </p>
                      <img tabindex="1" src="/assets/images/alpha.png" className="center-help shrink img-fluid mb-5"
                     data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>

                      <p className="fs-1 text-justify mb-4">
                         When available, users can select a ligand from the list in AlphaFill. The selected ligand will be highlighted in
                         green at the protein structure. AlphaFill algorithm utilizes sequence and structure similarity to "transplant" missing
                         ligands, cofactors, and (metal) ions into AlphaFold models. By incorporating the molecular context into these protein
                         structures, the models can be better understood regarding their function and structural integrity.
                         As a result, AlphaFill models can be valuable for designing downstream wet-lab experiments and computational studies.
                     </p>
                     <img tabindex="1" src="/assets/images/alphafill.png" className="center-help shrink img-fluid mb-5"
                     data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>

                     <h5 className="display-6 fw-bold mb-3">Intra- and Inter-protein contacts</h5>
                     <p className="fs-1 text-justify mb-5">
                        The following sections contain a list of intra- or inter-protein contacts obtained from experimental and
                        AlphaFold-predicted structures. The table includes the UniProt accession number, the PDB ID of the 3D structure,
                        the contact position, and the residue position in the protein sequence.
                        These data provide valuable insights into the intricate networks of interactions that underlie protein function and regulation.
                    </p>
                    <img tabindex="1" src="/assets/images/contacts.png" className="center-help shrink img-fluid mb-5"
                     data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                     <p className="fs-1 text-justify mb-4">
                     Furthermore, users can visualize the 3D structure and the sequence with contact position mapping by
                     selecting the respective elements. This feature enables users to gain insight into the contextual
                     information surrounding the contact. Users can select different structures from the panel on
                     the left and various UniProt accessions, if available.
                     Additionally, users can modify the Mol* viewer settings to suit their preferences.
                     </p>

                     <h5 className="display-6 fw-bold mb-3">Ligands</h5>
                     <p className="fs-1 text-justify mb-5">
                        The subsequent section encompasses comprehensive data regarding ligand interactions within the PDBe archive.
                        This information includes intricate details about the macromolecule chains involved and the functional classification
                        of each ligand, categorizing them as cofactor-like, drug-like, or reactant-like. Additionally,
                        it provides crucial identifiers such as InChIKey, bmID, and LigandType.
                    </p>
                    <img tabindex="1" src="/assets/images/ligands.png" className="center-help shrink img-fluid mb-5"
                     data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                     <p className="fs-1 text-justify mb-5">
                     Furthermore, users can visualize the 3D structure by selecting the respective elements.
                     This feature enables users to gain insight into the contextual
                     information surrounding the ligand. Users can select different structures from the panel on
                     the left. Additionally, users can modify the Mol* viewer settings to suit their preferences.
                     </p>
                     <h5 className="display-6 fw-bold mb-3">ClinVar</h5>
                      <p className="fs-1 text-justify mb-3">
                      The following section contains detailed ClinVar variant annotations that provide information about the specific human genetic variations classified for each residue's associated disease.
                      The variant annotation for each residue is presented in a table with 14 columns:</p>
                     <ul className="mb-5">
                    <li className="fs-1 text-justify">The 'Position ' column indicates the residue position in the protein sequence.</li>
                    <li className="fs-1 text-justify">The 'Location h19' column contains the location of the variants based on hg19.</li>
                    <li className="fs-1 text-justify">The 'Location h38' column contains the location of the variants based on hg38.</li>
                    <li className="fs-1 text-justify">The 'REF' column contains the reference allele.</li>
                    <li className="fs-1 text-justify">The 'ATL' column contains the alternative allele.</li>
                    <li className="fs-1 text-justify">The 'Variant ID' column contains the identifier(s) of co-located known variants.</li>
                    <li className="fs-1 text-justify">The 'Consequence' column contains the consequence type.</li>
                    <li className="fs-1 text-justify">The 'Clinical significance' column contains the ClinVar clinical significance of the dbSNP variant.</li>
                    <li className="fs-1 text-justify">The 'Disease' column contains the ClinVar-associated diseases.</li>
                    <li className="fs-1 text-justify">The 'Protein position' column contains the relative position of amino acid in protein.</li>
                    <li className="fs-1 text-justify">The 'AA change' column contains the reference and variant amino acids.</li>
                    <li className="fs-1 text-justify">The 'Codon' column contains the reference and variant codon sequence.</li>
                    <li className="fs-1 text-justify">The 'AlphaMissense score' column contains the predicted score of AlphaMissense, an amino acid level predictor of pathogenicity.</li>
                    <li className="fs-1 text-justify">The 'AlphaMissense class' column contains the predicted class of AlphaMissense, an amino acid level predictor of pathogenicity.</li>
                    </ul>
                     <img tabindex="1" src="/assets/images/clinvar.png" className="center-help shrink img-fluid mb-5"
                     data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                     <p className="fs-1 text-justify mb-2">
                        Users can view available experimental and predicted 3D structures with variant position mapping.
                        They can select different structures from the left panel and apply filters based on ClinVar clinical
                        significance, AlphaMissense classification, and Consequence column values. These filters can be applied
                        individually or in combination by selecting them from the list. Additionally, users can customize the Mol* viewer
                        settings to suit their preferences.
                     </p>
                      <p className="fs-1 text-justify mb-4">
                      Users can click on a row in the table to view a specific variant. This action will display the variant associated
                      with the selected structure. A summary of static data will also be shown based on the number of selected variants.
                      If a user selects a single variant, they will see the static data for that position and the static data for
                      the five positions directly before and after it. The static data for all chosen positions will be displayed for
                      users who select multiple variants. If a user does not select a variant, they will see a list ranking the top 10
                      residues based on the number of variants for each. Please note that filter options in the structure panel will not
                      be accessible while a variant is selected.
                       </p>
                       <img tabindex="1" src="/assets/images/summary.png" className="center-help shrink img-fluid mb-5"
                       data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                       <p className="fs-1 text-justify mb-2">
                       Users can access a residue summary for a selected variant position and the static data for the five positions  directly before and after it
                        </p>
                </div>
            </div>
        </div>
</section>

    <Footer/>
   	</>
   )
}

export default HelpPage