import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';


const AboutPage = () => {

  return (
    <>
      <Helmet>
        <title>Atlantis | About</title>
      </Helmet>
      <Header />
      <section className="px-5 py-10">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-sm-start text-center">
                    <h1 className="display-5 fw-bold mb-4">About</h1>

                    <p className="fs-1 text-justify">Understanding the functional role of specific residues is crucial for deciphering protein function, regulation, and interaction networks.
                    To this endeavor, we have created the Atlantis database, which provides an extensive resource for understanding the role of
                    specific residues in protein structures, complexes, and interaction networks. The database integrates various structural
                    and functional annotation layers for each residue, offering a comprehensive understanding of protein functionality.</p>
                    <p className="fs-1 mb-5 text-justify mb-2">Atlantis annotates 11,378,289 residues across 20,594 human proteins.
                    Additionally, it identifies 888,080 intra-protein contacts
                    in 55,736 PDB structures and 786,063 inter-protein contacts in 11,320 PDB structures.</p>
                      <h5 className="display-6 fw-bold mb-3">Methods</h5>
                     <p className="fs-1 mb-5 text-justify mb-2">
                      We extracted a list of all the UniProt accession numbers from the file containing the FASTA sequence for
                      the entire human proteome database. Next, we extracted and mapped each residue's structural
                      and functional annotation layers. Following that, we calculated the contacts for each PDB and AF structure.
                      </p>
                     <img tabindex="1" src="/assets/images/workflow.png" className="center-help shrink img-fluid mb-5"
                     data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>

                     <h5 className="display-6 fw-bold mb-3">GraphQL API</h5>
                      <p className="fs-1 mb-5 text-justify mb-2">We developed a GraphQL API to provide direct open access to data.
                      The API documentation <Link className="" to="/api/" target="_blank"><b>page</b></Link> offers comprehensive information about
                      the API's functionality and supported methods. Moreover, it enables quick and effortless data retrieval,
                      making it easier to locate structural and functional annotations and facilitating integration
                      in data analysis, machine learning pipelines, and more. </p>
                      <img tabindex="1" src="/assets/images/graphql.png" className="center-help shrink img-fluid mb-5"
                     data-toggle="tooltip" data-placement="top" title="Click to zoom-in"/>
                      <h5 className="display-6 fw-bold mb-3">Libraries</h5>
                    <ul class="mb-1 ml-10">
                        <li class="fs-1 text-justify">ReactJS (v18.2.0)</li>
                        <li class="fs-1 text-justify">Molstar (v4.6.0)</li>
                        <li class="fs-1 text-justify">Primereact (v10.5.0)</li>
                        <li class="fs-1 text-justify">React-bootstrap (v2.10.0)</li>
                        <li class="fs-1 text-justify">FastAPI (v0.104.1)</li>
                        <li class="fs-1 text-justify">Strawberry-graphql (v0.217.1)</li>
                        <li class="fs-1 text-justify mb-4">SQLAlchemy (v1.4.51)</li>
                    </ul>
                      <h5 className="display-6 fw-bold mb-3">Contact</h5>
                      <p class="fs-1 text-justify">Francesco Raimondi - francesco.raimondi@sns.it</p>
                </div>
            </div>
        </div>
</section>

      <Footer />
    </>
  );
};

export default AboutPage;
