import axios from 'axios';
import Swal from 'sweetalert2';

const genesData = require('./genes.json');
const proteinsData = require('./proteins.json');

function isValidUniProtAccession(input) {
  const found = proteinsData.some(protein => protein.PROTEIN_AN === input.trim());
  return found;
}

function isValidResidue(input) {
    const regex = /^[A-Z0-9]{6,10}(_[0-9]+)$/;
    const found = proteinsData.some(protein => protein.PROTEIN_AN === input.split("_")[0].trim());
    const test = regex.test(input.trim());
    let valid = false;

    if (test && found){
         valid = true;
    }
    return valid;
}

function isValidGeneSymbol(input) {
  const found = genesData.some(gene => gene.GENE_SYMBOL === input.trim());
  return found;
}

function isValidGeneResidue(input) {
    const regex = /^[A-Z0-9]{2,10}(_[0-9]+)$/;
    const found = genesData.some(gene => gene.GENE_SYMBOL === input.split("_")[0].trim());
    const test = regex.test(input.trim());
    let valid = false;

    if (test && found){
         valid = true;
    }
    return valid;
}

// Function to get intra-protein contact from API
export async function getIntraContact(target) {

       try {

        const query = {
              query: `
                query getResiduesByGene {
                  residuesByGeneSymbol(geneSymbol: "${target.toUpperCase()}", removeNoIntraContacts: true) {
                    anPosition
                    intraContacts {
                      proteinUid
                      pdbId
                      contactPosition
                    }
                  }
                }
              `
            };

        const query_protein = {
              query: `
                query getResiduesByProtein {
                  residues(proteinAn: "${target.toUpperCase()}", removeNoIntraContacts: true) {
                    anPosition
                    intraContacts {
                      proteinUid
                      pdbId
                      contactPosition
                    }
                  }
                }
              `
            };

        const query_residue = {
              query: `
                query getResidueByPosition {
                  residue(anPosition: "${target.toUpperCase()}") {
                    anPosition
                    intraContacts {
                      proteinUid
                      pdbId
                      contactPosition
                    }
                  }
                }
              `
            };

        const query_gene_residue = {
              query: `
                query getResidueByGeneAndPosition {
                  residueByGeneSymbolAndPosition(genePosition: "${target.toUpperCase()}") {
                    anPosition
                    intraContacts {
                      proteinUid
                      pdbId
                      contactPosition
                    }
                  }
                }
              `
            };

        let contactData = null;
        let type = "";
        const apiUrl = 'https://api.atlantis.bioinfolab.sns.it/graphql';

        if (isValidUniProtAccession(target) && !isValidResidue(target)){
           contactData = await axios.post(apiUrl, query_protein);
           type = "protein";
        } else if (isValidResidue(target)){
          contactData = await axios.post(apiUrl, query_residue);
          type = "residue";
        } else if (isValidGeneSymbol(target)) {
          contactData = await axios.post(apiUrl, query);
          type = "gene";
        }else if (isValidGeneResidue(target)) {
          contactData = await axios.post(apiUrl, query_gene_residue);
          type = "gene_residue";
        }


        const checkDataAndNavigate = () => {
          // Check for no errors and data is present
          if (!contactData.data.errors && contactData.data.data) {
            switch (type) {
              case "gene":
                 return contactData.data.data.residuesByGeneSymbol;
                break;
              case "residue":
                 return [contactData.data.data.residue];
                break;
              case "protein":
                 return contactData.data.data.residues;
                break;
              case "gene_residue":
                 return [contactData.data.data.residueByGeneSymbolAndPosition];
                break;
              default:
                return [];
            }
          }
        }

       return checkDataAndNavigate(); // Return the data retrieved

     } catch (error) {
          console.error(error);
       }
}

// Function to get inter-protein contact from API
export async function getInterContact(target) {

       try {

        const query = {
              query: `
                query getResiduesByGene {
                  residuesByGeneSymbol(geneSymbol: "${target.toUpperCase()}", removeNoInterContacts: true) {
                    anPosition
                    interContacts {
                      proteinUid
                      pdbId
                      contactPosition
                    }
                  }
                }
              `
            };

        const query_protein = {
              query: `
                query getResiduesByProtein {
                  residues(proteinAn: "${target.toUpperCase()}", removeNoInterContacts: true) {
                    anPosition
                    interContacts {
                      proteinUid
                      pdbId
                      contactPosition
                    }
                  }
                }
              `
            };

        const query_residue = {
              query: `
                query getResidueByPosition {
                  residue(anPosition: "${target.toUpperCase()}") {
                    anPosition
                    interContacts {
                      proteinUid
                      pdbId
                      contactPosition
                    }
                  }
                }
              `
            };

        const query_gene_residue = {
              query: `
                query getResidueByGeneAndPosition {
                  residueByGeneSymbolAndPosition(genePosition: "${target.toUpperCase()}") {
                    anPosition
                    interContacts {
                      proteinUid
                      pdbId
                      contactPosition
                    }
                  }
                }
              `
            };

        let contactData = null;
        let type = "";
        const apiUrl = 'https://api.atlantis.bioinfolab.sns.it/graphql';

        if (isValidUniProtAccession(target) && !isValidResidue(target)){
           contactData = await axios.post(apiUrl, query_protein);
           type = "protein";
        } else if (isValidResidue(target)){
          contactData = await axios.post(apiUrl, query_residue);
          type = "residue";
        } else if (isValidGeneSymbol(target)) {
          contactData = await axios.post(apiUrl, query);
          type = "gene";
        }else if (isValidGeneResidue(target)) {
          contactData = await axios.post(apiUrl, query_gene_residue);
          type = "gene_residue";
        }

        const checkDataAndNavigate = () => {
          // Check for no errors and data is present
          if (!contactData.data.errors && contactData.data.data) {
            switch (type) {
              case "gene":
                 return contactData.data.data.residuesByGeneSymbol;
                break;
              case "residue":
                 return [contactData.data.data.residue];
                break;
              case "protein":
                 return contactData.data.data.residues;
                break;
              case "gene_residue":
                 return [contactData.data.data.residueByGeneSymbolAndPosition];
                break;
              default:
                return [];
            }
          }
        }

       return checkDataAndNavigate(); // Return the data retrieved

     } catch (error) {
          console.error(error);
       }
}


// Function to get ligand from API
export async function getLigand(target) {

       try {

        const query = {
              query: `
                query getLigands {
                  ligandsByGene(geneSymbol: "${target.toUpperCase()}") {
                     pdbId
                     chainSymmetry
                     bmId
                     inchikey
                     ligandId
    			     ligandType
                     annotation
                  }
                }
              `
            };

        const query_protein = {
              query: `
                query getLigands {
                  ligandsByProtein(proteinAn: "${target.toUpperCase()}") {
                     pdbId
                     chainSymmetry
                     bmId
                     inchikey
                     ligandId
    			     ligandType
                     annotation
                  }
                }
              `
            };

        let ligandData = 0;
        let type = "";
        const apiUrl = 'https://api.atlantis.bioinfolab.sns.it/graphql';

        if (isValidUniProtAccession(target) && !isValidResidue(target)){
           ligandData = await axios.post(apiUrl, query_protein);
           type = "protein";
        } else if (isValidGeneSymbol(target)) {
          ligandData = await axios.post(apiUrl, query);
          type = "gene";
        }

        const checkDataAndNavigate = () => {
          // Check for no errors and data is present
          if (!ligandData.data.errors && ligandData.data.data) {
            switch (type) {
              case "gene":
                 return ligandData.data.data.ligandsByGene;
                break;
              case "protein":
                 return ligandData.data.data.ligandsByProtein;
                break;
              default:
                return [];
            }
          }
        }

       return checkDataAndNavigate(); // Return the data retrieved

     } catch (error) {
          console.error(error);
       }
}


// Function to get sequence mapping
export async function getSeqFeatures(target, uniAcc) {
    let i = 1;

    try {

        let i = 1;
        let proteinData = null;

        while (true) {

            const apiUrl = 'https://www.ebi.ac.uk/pdbe/graph-api/pdbe_pages/uniprot_mapping/' + target.toUpperCase() + '/' + i;

            const response = await axios.get(apiUrl);
            proteinData = response;

            if (proteinData.data && proteinData.data[target.toLowerCase()] && proteinData.data[target.toLowerCase()].data
            && proteinData.data[target.toLowerCase()].data[0].accession === uniAcc) {
                break;
            }
            i++;
        }

        if (!proteinData.data.errors && proteinData.data) {
            return i ;
        }
    } catch (error) {

        // Check if the error is a 404 (Not Found) error
        if (error.response && error.response.status === 404) {
            // Return an empty dictionary if a 404 error occurs
            return i;
        } else {
            // Log other errors to the console
            console.log(error);
             return i ;
        }
    }
}


// Get a list of PDB IDs by Uniprot Accession number to fill selection in variant tab
export function getPDBsFromUniprot(input, proteinPositions) {
  const maxPosition = Math.max(...proteinPositions)
  const minPosition = Math.min(...proteinPositions)
  const map = mapData.filter(pdb => pdb.SP_PRIMARY === input.trim());
  return map;
}

const mapData = require('./pdb_chain_uniprot.json');

// Get position in the PDB structure by Uniprot
function getNumbersFromString(input) {
    const numbers = input.match(/\d+/g);
    return numbers ? numbers.map(Number) : 0;
}

export async function getPDB2Uniprot(pdb, uniAcc, pos) {

    let position = 0;
    let chain = 'A';

    try{
          const query = {
          query: `
            query getPdbPosition($pos: String!, $pdb: String!, $uniAcc: String!) {
              getPdbPosition(uniprotPosition: $pos, pdb: $pdb, uniac: $uniAcc) {
                pdbresPos
              }
            }
          `,
          variables: {
            pos: pos.toString(),
            pdb: pdb,
            uniAcc: uniAcc
          }
        };

        const apiUrl = 'https://api.atlantis.bioinfolab.sns.it/graphql';
        const positionData = await axios.post(apiUrl, query);

        if (positionData.data){
            if (positionData.data.data.getPdbPosition){
                position = parseInt(positionData.data.data.getPdbPosition.pdbresPos.split("|")[1].match(/\d+/))
                chain = positionData.data.data.getPdbPosition.pdbresPos.split("|")[0];
            }
        }

    } catch (error) {
       console.error(error);
    }

    return { position, chain };
}



// Function to get ClinVar variants from API
export async function getVariants(target) {

       try {

        const query = {
              query: `
                query getResiduesByGene {
                  residuesByGeneSymbol(geneSymbol: "${target.toUpperCase()}", removeNoVariant: true) {
                    anPosition
                    clinvarVariants {
                      locationG37
                      locationG38
                      ref
                      alt
                      variant
                      consequence
                      clinicalSignificance
                      disease
                      proteinPosition
                      aaChange
                      codon
                      alphamissenseScore
                      alphamissenseClass
                    }
                  }
                }
              `
            };

        const query_protein = {
              query: `
                query getResiduesByProtein {
                  residues(proteinAn: "${target.toUpperCase()}", removeNoVariant: true) {
                    anPosition
                    clinvarVariants {
                      locationG37
                      locationG38
                      ref
                      alt
                      variant
                      consequence
                      clinicalSignificance
                      disease
                      proteinPosition
                      aaChange
                      codon
                      alphamissenseScore
                      alphamissenseClass
                    }
                  }
                }
              `
            };

        const query_residue = {
              query: `
                query getResidueByPosition {
                  residue(anPosition: "${target.toUpperCase()}") {
                    anPosition
                    clinvarVariants {
                      locationG37
                      locationG38
                      ref
                      alt
                      variant
                      consequence
                      clinicalSignificance
                      disease
                      proteinPosition
                      aaChange
                      codon
                      alphamissenseScore
                      alphamissenseClass
                    }
                  }
                }
              `
            };

        const query_gene_residue = {
              query: `
                query getResidueByGeneAndPosition {
                  residueByGeneSymbolAndPosition(genePosition: "${target.toUpperCase()}") {
                   anPosition
                    clinvarVariants {
                      locationG37
                      locationG38
                      ref
                      alt
                      variant
                      consequence
                      clinicalSignificance
                      disease
                      proteinPosition
                      aaChange
                      codon
                      alphamissenseScore
                      alphamissenseClass
                    }
                  }
                }
              `
            };

        let variantData = null;
        let type = "";
        const apiUrl = 'https://api.atlantis.bioinfolab.sns.it/graphql';

        if (isValidUniProtAccession(target) && !isValidResidue(target)){
           variantData = await axios.post(apiUrl, query_protein);
           type = "protein";
        } else if (isValidResidue(target)){
          variantData = await axios.post(apiUrl, query_residue);
          type = "residue";
        } else if (isValidGeneSymbol(target)) {
          variantData = await axios.post(apiUrl, query);
          type = "gene";
        }else if (isValidGeneResidue(target)) {
          variantData = await axios.post(apiUrl, query_gene_residue);
          type = "gene_residue";
        }


        const checkDataAndNavigate = () => {
          // Check for no errors and data is present
          if (!variantData.data.errors && variantData.data.data) {
            switch (type) {
              case "gene":
                 return variantData.data.data.residuesByGeneSymbol;
                break;
              case "residue":
                 return [variantData.data.data.residue];
                break;
              case "protein":
                 return variantData.data.data.residues;
                break;
              case "gene_residue":
                 return [variantData.data.data.residueByGeneSymbolAndPosition];
                break;
              default:
                return [];
            }
          }
        }

       return checkDataAndNavigate(); // Return the data retrieved

     } catch (error) {
          console.error(error);
     }
}

//// Function to send form data to the API
export async function sendExploreData(target, type) {
  try {
    const queries = {
      gene: {
        query: `
          query getResiduesByGene {
            residuesByGeneSymbol(geneSymbol: "${target.toUpperCase()}") {
              anPosition
              unRefProtRe
              afRes
              unAnnNatVariants
              unMoleculeProcessing
              unLocation
              unMotives
              unDomains
              pfamDomainPosHmm
              unLigandsBindingSite
              unAnnPropActiveSite
              phosPtms
              unGlycosylations
              unLipidations
              unModifiedRes
              unDisulfideBongBridges
              unSecondaryStructure
              afPredictedSecondaryStructure
              afConfidenceValue
              afMaxPredictedNumInteractionResidue
              afResiduePredictedInteraction
              unCrossLinks
              inBindingRegion
            }
          }
        `,
      },
      protein: {
        query: `
          query getResiduesByProtein {
            residues(proteinAn: "${target.toUpperCase()}") {
              anPosition
              unRefProtRe
              afRes
              unAnnNatVariants
              unMoleculeProcessing
              unLocation
              unMotives
              unDomains
              pfamDomainPosHmm
              unLigandsBindingSite
              unAnnPropActiveSite
              phosPtms
              unGlycosylations
              unLipidations
              unModifiedRes
              unDisulfideBongBridges
              unSecondaryStructure
              afPredictedSecondaryStructure
              afConfidenceValue
              afMaxPredictedNumInteractionResidue
              afResiduePredictedInteraction
              unCrossLinks
              inBindingRegion
            }
          }
        `,
      },
      residue: {
        query: `
          query getResidueByPosition {
            residue(anPosition: "${target.toUpperCase()}") {
              anPosition
              unRefProtRe
              afRes
              unAnnNatVariants
              unMoleculeProcessing
              unLocation
              unMotives
              unDomains
              pfamDomainPosHmm
              unLigandsBindingSite
              unAnnPropActiveSite
              phosPtms
              unGlycosylations
              unLipidations
              unModifiedRes
              unDisulfideBongBridges
              unSecondaryStructure
              afPredictedSecondaryStructure
              afConfidenceValue
              afMaxPredictedNumInteractionResidue
              afResiduePredictedInteraction
              unCrossLinks
              inBindingRegion
            }
          }
        `,
      },
      gene_residue: {
        query: `
          query getResidueByGeneAndPosition {
            residueByGeneSymbolAndPosition(genePosition: "${target.toUpperCase()}") {
              anPosition
              unRefProtRe
              afRes
              unAnnNatVariants
              unMoleculeProcessing
              unLocation
              unMotives
              unDomains
              pfamDomainPosHmm
              unLigandsBindingSite
              unAnnPropActiveSite
              phosPtms
              unGlycosylations
              unLipidations
              unModifiedRes
              unDisulfideBongBridges
              unSecondaryStructure
              afPredictedSecondaryStructure
              afConfidenceValue
              afMaxPredictedNumInteractionResidue
              afResiduePredictedInteraction
              unCrossLinks
              inBindingRegion
            }
          }
        `,
      },
    };

    // Select the appropriate query based on the type
    const query = queries[type];
    if (!query) {
      Swal.fire({
        icon: "info",
        text: "Invalid search type! Please check your criteria and try again.",
      });
      return null;
    }

    const apiUrl = "https://api.atlantis.bioinfolab.sns.it/graphql";
    const response = await axios.post(apiUrl, query);

    // Check if the response has data and no errors
    if (response?.data?.errors) {
      console.error("API Errors:", response.data.errors);
      Swal.fire({
        icon: "error",
        text: "An error occurred while fetching data from the server.",
      });
      return null;
    }

    const data = response.data.data;
    if (!data) {
      console.error("No data received from API");
      return null;
    }

    // Handle data based on the type and return the appropriate data
    switch (type) {
      case "gene":
        return data.residuesByGeneSymbol?.length > 0 ? { data } : null;
      case "protein":
        return data.residues?.length > 0 ? { data } : null;
      case "residue":
        return data.residue ? { data } : null;
      case "gene_residue":
        return data.residueByGeneSymbolAndPosition ? { data } : null;
      default:
        return null;
    }
  } catch (error) {
    console.error("Error in sendExploreData:", error.message);
    Swal.fire({
      icon: "error",
      text: "An error occurred: " + error.message,
    });
    return null;
  }
}

// Create colors
export function createDomainsColors(dataResidue) {
    const domains = dataResidue
        .filter(residue => residue.unDomains.trim() !== "no_domain")
        .map(residue => residue.unDomains);

    // Remove duplicates from the domains array
    const uniqueDomains = [...new Set(domains)];

    const colors = {};

    const color_map = [
            "ff5733", // Bright Red
            "33ff57", // Bright Green
            "3357ff", // Bright Blue
            "ff33a1", // Bright Pink
            "a1ff33", // Lime Green
            "ffac33", // Bright Orange
            "33ffd7", // Cyan
            "f3ff33", // Yellow
            "ff33c4", // Magenta
            "5733ff", // Purple
            "ff3371", // Light Red
            "33ff8a", // Teal
            "ffb833", // Gold
            "338cff", // Sky Blue
            "f033ff", // Violet
            "ff9933", // Amber
            "33ff6e", // Sea Green
            "ff5f33", // Coral
            "33d3ff", // Azure
            "ff3333", // Crimson
            "ff9933", // Peach
            "ff5733", // Tomato
            "33ff57", // Lime
            "6b33ff", // Indigo
            "ff33f5"  // Hot Pink
       ];

    uniqueDomains.forEach((domain, index) => {
        // Assign a color from color_map based on the index
        const colorIndex = index % color_map.length; // Loop back if out of bounds
        colors[domain] = `${color_map[colorIndex]}`; // Convert to hex
    });
    return colors;
}

export async function getInterProDomains(uniAcc) {

    let domainList = [];

    try {
        const query = {
            query: `
                query getInterproDomains($uniAcc: String!) {
                  getInterproDomains(uniprotAccession: $uniAcc) {
                    interproEntry
                    domainName
                    start
                    end
                    color
                  }
                }
            `,
            variables: {
                uniAcc: uniAcc,
            },
        };

        const apiUrl = 'https://api.atlantis.bioinfolab.sns.it/graphql';
        const domains = await axios.post(apiUrl, query);

        if (domains.data) {
            console.log(domains.data);
            if (domains.data.data.getInterproDomains) {
                domainList = domains.data.data.getInterproDomains;
            }
        }
    } catch (error) {
        console.error(error);
    }

    // domainList will now always be defined
    return domainList;
}
