import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ContactTable from '../../components/ContactTable/ContactTable';
import ResiduesTable from '../../components/ResiduesTable/ResiduesTable';
import LigandTable from '../../components/LigandTable/LigandTable';
import VariantTable from '../../components/VariantTable/VariantTable';
import GraphlqlQuery from '../../components/GraphlqlQuery/GraphlqlQuery';
import BarChartVariant from '../../components/BarChartVariant/BarChartVariant';
import BarChartFunctional from '../../components/BarChartFunctional/BarChartFunctional';
import VariantSelect from '../../components/VariantSelect/VariantSelect';
import FunctionalAnnotationStructureLegend from '../../components/FunctionalAnnotationStructureLegend/FunctionalAnnotationStructureLegend';
import { Divider } from 'primereact/divider';
import { Tooltip } from 'primereact/tooltip';
import 'animate.css';
import 'primeicons/primeicons.css';
import { getIntraContact, getInterContact, getLigand, getSeqFeatures, getVariants, getPDBsFromUniprot, getPDB2Uniprot, sendExploreData, createDomainsColors, getInterProDomains} from '../../ApiFunctions.js';
import { TabView, TabPanel } from 'primereact/tabview';
import MolstarViewer from '../../components/MolstarViewer/MolstarViewer';
import MolstarViewerFunctional from '../../components/MolstarViewerFunctional/MolstarViewerFunctional';
import { Link } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';
import { Message } from 'primereact/message';


const ResultPage = () => {

const navigate = useNavigate();
const location = useLocation();
const [loadFunctionalInfo, setLoadFunctionalInfo] = useState(false);

const [target, setTarget] = useState("");
const [type, setType] = useState("");
const [info, setInfo] = useState([]);

useEffect(() => {

        const query = new URLSearchParams(location.search);
        const urlTarget = query.get('target');
        const urlType = query.get('type');

        if (!location.state) {

            // Set state from URL parameters
            setTarget(urlTarget);
            setType(urlType);
            setLoadFunctionalInfo(true);

            if(urlType && urlTarget){

                 sendExploreData(urlTarget, urlType)
              .then(data => {
                if (data) {
                  setInfo(data.data || []);
                  setLoadFunctionalInfo(false);
                } else {
                  console.log('No data received!');
                  setInfo([]); // Handle empty or undefined data
                  setLoadFunctionalInfo(false);
                }
              })
              .catch(error => {
                console.log('Error fetching data:', error);
                setInfo([]); // Handle the error scenario
                setLoadFunctionalInfo(false);
              });


            } else {
            // If neither URL parameters nor location.state are present, navigate to home
            navigate('/');
        }

        } else if (location.state) {
            // Fallback to location.state if URL parameters are not present
            const state = location.state;
            setTarget(state.target || "");
            setType(state.type || "");
            setInfo(state.data || []);
            setLoadFunctionalInfo(false);

        } else {
            // If neither URL parameters nor location.state are present, navigate to home
            navigate('/');
        }
    }, [location.search, location.state, navigate]);

const residueGeneData =  [info.residueByGeneSymbolAndPosition] || [];
const datatable = info.residues ||  info.residuesByGeneSymbol ||  [info.residue]  || [];

const [activeTabIndex, setActiveTabIndex] = useState(0);

// Dialog settings
const [visible, setVisible] = useState(false);
const [visibleIntraDialog, setVisibleIntraDialog] = useState(false);
const [visibleInterDialog, setVisibleInterDialog] = useState(false);
const [visibleLigand, setVisibleLigand] = useState(false);
const [visibleVariant, setVisibleVariant] = useState(false);
const [position, setPosition] = useState('center');
const [positionIntraDialog, setPositionIntraDialog] = useState('center');
const [positionInterDialog, setPositionInterDialog] = useState('center');
const [positionVariant, setPositionVariant] = useState('center');
const [positionLigand, setPositionLigand] = useState('center');
const [uniprotAccessionNumber, setUniprotAccessionNumber] = useState();
const [uniprotNumber, setUniprotNumber] = useState([]);

const show = (position) => {
        setPosition(position);
        setVisible(true);
};

const showIntraDialog = (positionIntraDialog) => {
        setPositionIntraDialog(positionIntraDialog);
        setVisibleIntraDialog(true);
};

const showInterDialog = (positionInterDialog) => {
        setPositionInterDialog(positionInterDialog);
        setVisibleInterDialog(true);
};

const showLigand = (positionLigand) => {
        setPositionLigand(positionLigand);
        setVisibleLigand(true);
};

const showVariant = (positionVariant) => {
        setPositionVariant(positionVariant);
        setVisibleVariant(true);
};

   // Processing data
   let data = null;
   let colors = {};
   let localUniprotNumber = null;

   const [domainInterPro, setDomainInterPro] = useState(null);

    if (Array.isArray(datatable) && datatable.length >= 1 && datatable[0] !== undefined) {
        data = datatable.map(residue => {
            const { interContacts, intraContacts, ...filteredResidue } = residue;
            return filteredResidue;
        });

        colors = createDomainsColors(data);
        localUniprotNumber = data[0]?.anPosition.split("_")[0];
    } else if (Array.isArray(residueGeneData) && residueGeneData.length >= 1 && residueGeneData[0] !== undefined) {
        data = residueGeneData.map(residue => {
            const { interContacts, intraContacts, ...filteredResidue } = residue;
            return filteredResidue;
        });

        colors = createDomainsColors(data);
        localUniprotNumber = data[0]?.anPosition.split("_")[0];
    }

   useEffect(() => {
    const fetchDomains = async () => {
        try {
            const result = await getInterProDomains(uniprotNumber);
            setDomainInterPro(result); // Update state
            console.log(result); // Log the result
        } catch (error) {
            console.error('Error fetching InterPro domains:', error);
        }
    };

    fetchDomains();
    }, [uniprotNumber]); // Fetch domains when uniprotNumber changes

    useEffect(() => {
        if (data) {
            const localNumber = data[0]?.anPosition.split("_")[0];
            if (localNumber && localNumber !== uniprotNumber) {
                setUniprotNumber(localNumber);
            }
        }
    }, [data]); // Update uniprotNumber only when `data` changes


   //Set tabs data
   const [intraLoadData, setIntraLoadData] = useState(false);
   const [interLoadData, setInterLoadData] = useState(false);
   const [variantLoadData, setVariantLoadData] = useState(false);
   const [ligandLoadData, setLigandLoadData] = useState(false);
   const [intraContactsData, setIntraContactsData] = useState([]);
   const [interContactsData, setInterContactsData] = useState([]);
   const [ligandData, setLigandData] = useState([]);
   const [variantData, setVariantData] = useState([]);
   const [annotation, setAnnotation] = useState("pLDDT");

  // Control Calls
  const [callNumberIntra, setCallNumberIntra] = useState(1);
  const [callNumberInter, setCallNumberInter] = useState(1);
  const [callNumberLig, setCallNumberLig] = useState(1);
  const [callNumberVar, setCallNumberVar] = useState(1);

  // Handling structure selection
  const [uniqueIntraContactsPdbIds, setUniqueIntraContactsPdbIds] = useState([]);
  const [uniqueInterContactsPdbIds, setUniqueInterContactsPdbIds] = useState([]);
  const [entity, setEntity] = useState();
  const [uniqueLigandPdbIds, setUniqueLigandPdbIds] = useState([]);
  const [uniqueVariantPdbIds, setUniqueVariantPdbIds] = useState([]);
  const [structurePDBIdIntra, setStructurePDBIdIntra] = useState();
  const [structurePDBIdInter, setStructurePDBIdInter] = useState();
  const [structurePDBIdLigand, setStructurePDBIdLigand] = useState();
  const [structurePDBIdVariant, setStructurePDBIdVariant] = useState("");
  const [seqAccession, setSeqAccession] = useState();
  const [uniqueInterContactsUniprotAccession, setUniqueInterContactsUniprotAccession] = useState([]);

  const [positions, setPositions] = useState([]);
  const [positionsInter, setPositionsInter] = useState([]);
  const [positionsVar, setPositionsVar] = useState([]);
  const [positionsVarPDB, setPositionsVarPDB] = useState([]);
  const [database, setDatabase]= useState("PDB");
  const [chain, setChain] = useState('A');
  const [positionSummary, setPositionSummary] = useState();

  // Filters Variant tab
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedLigand, setSelectedLigand] = useState(null);
  const [ligandsOption, setLigandsOption] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [wasSelectedVariants, setWasSelectedVariants] = useState(false);
  const [itemsClinical, setItemsClinical] =  useState([]);
  const [itemsAAClass, setItemsAAClass] =  useState([]);
  const [itemsConsequence, setItemsConsequence] =  useState([]);
  const groupedFilters = [{
                        label: 'Clinical Significance',
                        items: itemsClinical
                        },{
                         label: 'AlphaMissense class',
                         items: itemsAAClass
                        },{
                         label: 'Consequence',
                         items: itemsConsequence
                        }
                    ];

  const groupTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <div>{option.label}</div>
            </div>
        );
    };

   // Apply filter in variants data showing in the structure
   const [loading, setLoading] = useState(false);

const filterVariantReset = () => {
       setSelectedFilter([]);

       // Mapping variant
        const filteredData = variantData.map(variant => variant.proteinPosition);

        if (filteredData) {
               const uniqueProteinPosition = [...new Set(filteredData)];
               setPositionsVar(uniqueProteinPosition);
               setLoading(false);
        }

   }

const filterVariant = () => {

    // Set loading to true to indicate data has stared to be processed
    setLoading(true);
    setPositionsVar([]);

    // Initialize an empty set to store unique protein positions
    const proteinPositionsSet = new Set();

    // Initialize arrays to keep track of conditions for each filter type
    const consequenceFilters = [];
    const alphamissenseClassFilters = [];
    const clinicalSignificanceFilters = [];

    // Iterate through each value in the selectedFilter array to classify and store filters
    selectedFilter.forEach(filterValue => {
      // Remove the suffix (_aa, _cli, _cos) from filterValue
      const baseFilterValue = filterValue.replace(/(_aa|_cli|_cos)$/, '');

      // Determine which field to filter by based on the original filterValue suffix
      if (filterValue.endsWith('_aa')) {
        alphamissenseClassFilters.push(baseFilterValue);  // Store alphamissenseClass filter values
      } else if (filterValue.endsWith('_cli')) {
        clinicalSignificanceFilters.push(baseFilterValue);  // Store clinicalSignificance filter values
      } else if (filterValue.endsWith('_cos')) {
        consequenceFilters.push(baseFilterValue);  // Store consequence filter values
      }
    });

    // Filter the data based on all filter combinations
    variantData.forEach(variant => {

      // Check if the variant matches all the criteria
      const matchesConsequence = consequenceFilters.length === 0 || consequenceFilters.some(filter =>
        variant.consequence && variant.consequence.includes(filter)
      );
      const matchesAlphamissenseClass = alphamissenseClassFilters.length === 0 || alphamissenseClassFilters.includes(variant.alphamissenseClass);

      const matchesClinicalSignificance = clinicalSignificanceFilters.length === 0 || clinicalSignificanceFilters.some(filter =>
        variant.clinicalSignificance && variant.clinicalSignificance.includes(filter)
      );

      // If all conditions are met, add the proteinPosition to the set
      if (matchesConsequence && matchesAlphamissenseClass && matchesClinicalSignificance) {
        proteinPositionsSet.add(variant.proteinPosition);
      }
    });


    if ( selectedFilter.length === 0 ) {
        variantData.forEach(variant => proteinPositionsSet.add(variant.proteinPosition));
    }

    // Convert the Set to an array to get unique protein positions
    const uniqueProteinPositions = Array.from(proteinPositionsSet);

    // Update the state with unique protein positions
    setPositionsVar(uniqueProteinPositions);

    // Set loading to false to indicate data has been processed
    setLoading(false);


    };

// Get intra-contact data
const handleIntraContact = async () => {
    if (activeTabIndex === 1 && intraContactsData.length === 0) {
        setIntraLoadData(true);

        try {

            const contactData = await getIntraContact(target);

            if (contactData && Array.isArray(contactData) && contactData.length > 0) {
                const intraContacts = contactData.flatMap(residue =>
                    (residue.intraContacts && residue.intraContacts.length > 0) ? residue.intraContacts.map(contact => ({
                        ...contact,
                        anPosition: residue.anPosition
                    })) : []
                );

                setIntraContactsData(intraContacts);

                // Creating constants for unique PDB IDs
                const pdbIds = intraContacts.map(contact => contact.pdbId);
                const uniquePdbIds = [...new Set(pdbIds)];
                setUniqueIntraContactsPdbIds(uniquePdbIds);
                setStructurePDBIdIntra(uniquePdbIds[0]);

                // Highlight contacts
                const filteredData = intraContacts
                    .filter(contact => contact.pdbId.toUpperCase()  === uniquePdbIds[0].toUpperCase() )
                    .map(contact => ( contact.contactPosition ));
                const uniqueContact = [...new Set(filteredData)];

                const uniAcc = intraContacts
                    .filter(contact => contact.pdbId.toUpperCase() === uniquePdbIds[0].toUpperCase())
                    .map(contact => ( contact.proteinUid ));

                if (uniqueContact){
                    setUniprotAccessionNumber(uniAcc[0]);
                    structureMap(uniqueContact, 'intra', uniquePdbIds[0], uniAcc[0]);
                }

            } else {
                // No intraContacts data available
                setIntraContactsData([]);
                setPositions([]);
            }
        } catch (error) {
            console.error("Error fetching intra-contact data:", error);
        } finally {
            setIntraLoadData(false);
        }

        setCallNumberIntra(callNumberIntra + 1);
    }
}

// Get inter-contact data
const handleInterContact = async () => {
    if (activeTabIndex === 2 && interContactsData.length === 0) {
        setInterLoadData(true);

        try {

            const contactData = await getInterContact(target);

            if (contactData && Array.isArray(contactData) && contactData.length > 0) {

                const interContacts = contactData.flatMap(residue =>
                    (residue.interContacts && residue.interContacts.length > 0) ? residue.interContacts.map(contact => ({
                        ...contact,
                        anPosition: residue.anPosition
                    })) : []
                );

                setInterContactsData(interContacts);

                // Creating constants for unique PDB IDs
                const pdbIds = interContacts.map(contact => contact.pdbId);
                const uniquePdbIds = [...new Set(pdbIds)];
                setUniqueInterContactsPdbIds(uniquePdbIds);
                setStructurePDBIdInter(uniquePdbIds[0]);


                // Highlight contacts
                const filteredData = interContacts
                    .filter(contact => contact.pdbId.toUpperCase() === uniquePdbIds[0].toUpperCase())
                    .map(contact => ( contact.contactPosition ));
                const uniqueContact = [...new Set(filteredData)];

                const uniAcc = interContacts
                    .filter(contact => contact.pdbId.toUpperCase() === uniquePdbIds[0].toUpperCase())
                    .map(contact => ( contact.proteinUid));

                 const uniqueUniAcc = [...new Set(uniAcc)];

                setUniqueInterContactsUniprotAccession(uniqueUniAcc);
                setSeqAccession(uniqueUniAcc[0]);

                if (uniqueContact){
                    setUniprotAccessionNumber(uniAcc[0]);
                    structureMap(uniqueContact, 'inter', uniquePdbIds[0], uniAcc[0]);
                }

            } else {
                // No interContacts data available
                setInterContactsData([]);
            }
        } catch (error) {
            console.error("Error fetching inter-contact data:", error);
        } finally {
            setInterLoadData(false);
        }
         setCallNumberInter(callNumberInter + 1);
    }
}


// Get ligand data
const handleLigand = async () => {

    if (activeTabIndex === 3 && ligandData.length === 0) {

        setLigandLoadData(true);

        try {
            const data = await getLigand(target);

            if (data && Array.isArray(data) && data.length > 0) {

                setLigandData(data);

                // Creating constants for unique PDB IDs
                const pdbIds = data.map(ligand => ligand.pdbId);
                const uniquePdbIds = [...new Set(pdbIds)];
                setUniqueLigandPdbIds(uniquePdbIds);
                setStructurePDBIdLigand(uniquePdbIds[0]);

            } else {
                // No interContacts data available
                setLigandData([]);
            }
        } catch (error) {
            console.error("Error fetching ligand data:", error);
        } finally {
            setLigandLoadData(false);
        }
         setCallNumberLig(callNumberLig + 1);
    }
}

const handleVariant = async () => {

    if (activeTabIndex === 4 && variantData.length === 0) {
        setVariantLoadData(true);
        try {
            const ClinVarData = await getVariants(target);
            if (ClinVarData && Array.isArray(ClinVarData) && ClinVarData.length > 0) {
                const variantDta = ClinVarData.flatMap(residue =>
                    (residue.clinvarVariants && residue.clinvarVariants.length > 0) ? residue.clinvarVariants.map(variant => ({
                        ...variant,
                        anPosition: residue.anPosition
                    })) : []
                );
                setVariantData(variantDta);

                let uniac = "";
                let pdbList = [];
                let uniquePdbIds = [];

                if (variantDta.length > 0 ) {

                    // Create filter lists to filter variant in the structure viewer
                    const filteredClinicalSignificance = variantDta.map(variant => variant.clinicalSignificance);

                    // Split values by comma and flatten the array
                    const splitClinicalSignificance = filteredClinicalSignificance
                       .flatMap(significance => significance.split(/[,/]/).map(val => val.trim())); // Split by comma or slash and trim spaces

                    // Get unique values using Set
                    const uniqueClinicalSignificance = [...new Set(splitClinicalSignificance)];

                    // Create the list of objects with { label: '', value: '' } format
                    const clinicalSignificanceOptions = uniqueClinicalSignificance.map(significance => ({
                      label: significance,
                      value: significance  + '_cli'
                    }));

                    setItemsClinical(clinicalSignificanceOptions);

                    const filteredAAClass = variantDta.map(variant => variant.alphamissenseClass);
                    const uniqueAAClass = [...new Set(filteredAAClass)];

                    const aaOptions = uniqueAAClass.map(aaClass => ({
                      label: aaClass,
                      value: aaClass + '_aa'
                    }));

                    setItemsAAClass(aaOptions);

                    const filteredConsequence = variantDta.map(variant => variant.consequence);

                    // Split values by comma and flatten the array
                    const splitConsequence = filteredConsequence
                       .flatMap(significance => significance.split(/[,/]/).map(val => val.trim())); // Split by comma or slash and trim spaces

                    const uniqueConsequence = [...new Set(splitConsequence)];

                    const consequenceOptions = uniqueConsequence.map(variantConsequence => ({
                      label: variantConsequence,
                      value: variantConsequence  + '_cos'
                    }));

                    setItemsConsequence(consequenceOptions);

                    uniac = variantDta[0].anPosition.split("_")[0];

                    setUniprotAccessionNumber(uniac);

                    // Get protein position of variants
                    const filteredData = variantDta.map(variant => variant.proteinPosition);
                    const uniqueProteinPosition = [...new Set(filteredData)];

                    // Get PDB list for Uniprot Accession number
                    pdbList = await getPDBsFromUniprot(uniac, uniqueProteinPosition);

                    if (pdbList.length > 0) {

                        // Creating constants for unique PDB IDs
                        const pdbIds = pdbList.map(map => map.PDB);
                        uniquePdbIds = [...new Set(pdbIds)];
                        uniquePdbIds.push("AF-" + uniac);
                        setUniqueVariantPdbIds(uniquePdbIds);
                        setStructurePDBIdVariant(uniquePdbIds[0]);

                         // Mapping variant
                        if (filteredData && variantDta.length < 50) {

                            if (database === 'PDB'){
                               structureMap(uniqueProteinPosition, 'variants', uniquePdbIds[0], uniac);
                               setDatabase('PDB');

                            }

                        }

                    } else {

                        uniac = "AF-" + uniac;
                        setUniqueVariantPdbIds([uniac]);
                        setStructurePDBIdVariant(uniac);

                        // Mapping variant
                        const filteredData = variantDta.map(variant => variant.proteinPosition);

                        if (filteredData && variantDta.length < 50) {
                            const uniqueProteinPosition = [...new Set(filteredData)];
                            setPositionsVar(uniqueProteinPosition);
                            setDatabase("AF");
                        }
                    }

                }
            }
        } catch (error) {
            console.error("Error fetching variant data:", error);
        } finally {
            setVariantLoadData(false);
        }
        setCallNumberVar(callNumberVar + 1);
    }
};


// get contact data when click in tab's name
useEffect(() => {

    if (activeTabIndex === 1 && callNumberIntra === 1) {
      handleIntraContact();
    } else if (activeTabIndex === 1 && callNumberIntra !== 1){
        // Create a new 'change' event
        const event = new Event('change', { bubbles: true });
        // Create a custom object to simulate the event's target
        Object.defineProperty(event, 'target', { value: { value: structurePDBIdIntra }, writable: true });
        handlePDBidIntra(event);
    }
    if (activeTabIndex === 2 && callNumberInter === 1) {
      handleInterContact();
    } else if (activeTabIndex === 2 && callNumberInter !== 1){
        // Create a new 'change' event
        const event = new Event('change', { bubbles: true });
        // Create a custom object to simulate the event's target
        Object.defineProperty(event, 'target', { value: { value: structurePDBIdInter }, writable: true });
        handlePDBidInter(event);

    }
    if (activeTabIndex === 3 && callNumberLig === 1) {
      handleLigand();
    }

    if (activeTabIndex === 4 && callNumberVar === 1) {
       handleVariant();
    }

}, [activeTabIndex, callNumberIntra, callNumberInter, callNumberLig, callNumberVar]);

const handlePDBidIntra = async (e) => {
    setStructurePDBIdIntra(e.target.value);

    const filteredData = intraContactsData
        .filter(contact => contact.pdbId.toUpperCase() === e.target.value.toUpperCase())
        .map(contact => contact.contactPosition);

    const uniqueContact = [...new Set(filteredData)];

    const uniAcc = intraContactsData
        .filter(contact => contact.pdbId.toUpperCase() === e.target.value.toUpperCase())
        .map(contact => contact.proteinUid);

    setUniprotAccessionNumber(uniAcc[0]);
    setPositions([]);
    structureMap(uniqueContact, 'intra', e.target.value, uniAcc[0]);
};


const handleSeqAccession = async (e) => {
    setSeqAccession(e.target.value);

    const filteredData = interContactsData
        .filter(contact =>
            contact.pdbId.toUpperCase() === structurePDBIdInter.toUpperCase() &&
            contact.proteinUid.toUpperCase() === e.target.value.toUpperCase())
        .map(contact => contact.contactPosition);

    const uniqueContact = [...new Set(filteredData)];

    setPositionsInter([]);
    setUniprotAccessionNumber(e.target.value);
    structureMap(uniqueContact, 'inter', structurePDBIdInter, e.target.value);


};

const handlePDBidInter = async (e) => {
    setStructurePDBIdInter(e.target.value);

    const uniAcc = interContactsData
                    .filter(contact => contact.pdbId.toUpperCase() === e.target.value.toUpperCase())
                    .map(contact => ( contact.proteinUid));

    const uniqueUniAcc = [...new Set(uniAcc)];
    setUniqueInterContactsUniprotAccession(uniqueUniAcc);

    const filteredData = interContactsData
        .filter(contact =>
            contact.pdbId.toUpperCase() === e.target.value.toUpperCase() &&
            contact.proteinUid.toUpperCase() === uniqueUniAcc[0])
        .map(contact => contact.contactPosition);

    const uniqueContact = [...new Set(filteredData)];
    setSeqAccession(uniqueUniAcc[0]);

    setUniprotAccessionNumber(uniAcc[0]);
    setPositionsInter([]);
    structureMap(uniqueContact, 'inter', e.target.value, uniqueUniAcc[0]);
};


async function structureMap(contacts, type, pdb, uniAcc) {

  if (!contacts || contacts.length === 0) {
    return; // Exit early if contacts is falsy or empty
  }

  const mapPos = [];

  try {
    // Create an array of promises for all the getPDB2Uniprot calls
    const promises = contacts.map(contact => getPDB2Uniprot(pdb, uniAcc, parseInt(contact)));

    // Wait for all promises to resolve
    const results = await Promise.all(promises);

    // Process the results
    results.forEach(result => {

     const { position, chain } = result;

          if (position !== 0) {
              mapPos.push(position);
           }

          // If new_chain is provided, update it
          if (chain && chain !== 'undefined' && position !== 0) {
              setChain(chain);
           }
    });

    // Based on type, call respective setPositions function
    if (type === 'intra') {
      setPositions(mapPos);
      setDatabase('PDB');
    } else if (type === 'inter') {
      setPositionsInter(mapPos);
      setDatabase('PDB');
    } else if (type === 'variants') {
      setPositionsVar(mapPos);
      setPositionsVarPDB(mapPos);
    }
  } catch (error) {
    console.error('Error in structureMap:', error);
  }
}

const handlePDBidLigand = (e) => {
     setStructurePDBIdLigand(e.target.value);
};

const handleVariantPosition = (e) => {
     setPositionSummary(e.target.value);
};

const handlePDBidVariant = (e) => {

    const value = e.target.value;
    setStructurePDBIdVariant(value);

    // Get protein position of variants
    const filteredData = variantData.map(variant => variant.proteinPosition);
    let uniqueProteinPosition = [...new Set(filteredData)];

    if (selectedVariants && selectedVariants.length > 0) {
      uniqueProteinPosition = uniqueProteinPosition.filter(position => selectedVariants.includes(position))
    }

    if (value.includes("AF-")) {

         setPositionsVar(uniqueProteinPosition);
         setSelectedFilter([]);
         setDatabase("AF");
         setChain("A");

    } else{

        structureMap(uniqueProteinPosition, 'variants', value, uniprotAccessionNumber);

        console.log(chain, value, positionsVar);
        setDatabase("PDB");
        setSelectedFilter([]);
    }

};

const handleAFStructure = (uniquePositions) => {
        setPositionsVar(uniquePositions);
        setSelectedFilter([]);
        setDatabase("AF");
        setChain("A");
    };

const handlePDBStructure = (uniquePositions) => {
        structureMap(uniquePositions, 'variants', structurePDBIdVariant, uniprotAccessionNumber);
        setDatabase("PDB");
        setSelectedFilter([]);
    };

const getUniquePositions = (variants) => [...new Set(variants)];


// Show variant on the structure by click on table row
useEffect(() => {

    // Main logic
    if (selectedVariants.length > 0) {

        const uniquePositions = getUniquePositions(selectedVariants);

        setWasSelectedVariants(true);

        if (structurePDBIdVariant.includes("AF-")) {
            handleAFStructure(uniquePositions);
        } else {
            handlePDBStructure(uniquePositions);
        }

    } else if (variantData.length < 50 && selectedVariants.length === 0 && wasSelectedVariants === true) {

        const uniquePositions = getUniquePositions(variantData.map(variant => variant.proteinPosition));

        if (structurePDBIdVariant.includes("AF-")) {
            handleAFStructure(uniquePositions);
        } else {
            handlePDBStructure(uniquePositions);
        }

    } else if (variantData.length > 50 && selectedVariants.length === 0) {
         setPositionsVar([]);
    }

}, [selectedVariants]);


// Set annotation
const handleAnnotation = (e) => {
    setAnnotation(e.target.value);
};


// Reset list of selected ligands
const filterLigandsReset = () => {
    setSelectedLigand([]);
}


return (
    <>
      <Helmet>
        <title> Atlantis | {target}</title>
      </Helmet>
      <Header />
      <section className="py-9">
        <div className="container">
        <div className="row mb-4">
            <div className="col-12">
             <h1 className="display-5 fw-bold title-explore mb-3">
              {target}
              {type === "gene" && (
                <img
                  alt="logo"
                  src="/assets/images/dna.png"
                  className="img-fluid btn-img"
                  style={{ verticalAlign: 'middle' }}
                />
              )}
              {type === "protein" && (
                <img
                  alt="logo"
                  src="/assets/images/protein.png"
                  className="img-fluid btn-img"
                  style={{ verticalAlign: 'middle' }}
                />
              )}
              { (type === "residue" || type === "gene_residue")&& (
                <img
                  alt="logo"
                  src="/assets/images/chemist.png"
                  className="img-fluid btn-img"
                  style={{ verticalAlign: 'middle' }}
                />
              )}
            </h1>

            </div>
          </div>
    <TabView scrollable activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
    <TabPanel header="Functional site information">
            <div className="row mb-4">
            <div className="col-12">
               <h2 className="display-6 fw-bold mb-5">Functional site information<sup><Button icon="pi pi-info"
               onClick={() => show('top-right')} text size="small" className="btn-dialog"/></sup></h2>
               <Dialog header="Functional site information" visible={visible} position={position} style={{ width: '50vw' }} onHide={() => setVisible(false)}
                draggable={false} resizable={false}  breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <p className="m-0 mb-1 text-justify">
                    This section provides various types of annotations at the residue level, including UniProt annotations such
                    as variants, molecule processing, membrane localization, motifs, domains, glycosylations, lipidations,
                    modified residues, disulfide bonds, and secondary structures. Protein architecture information is provided
                    through PFAM and InterPro domains and aligned positions in the Hidden Markov Model (HMM). Additionally,
                    it includes information on IntAct binding regions and protein post-translational modifications
                    sourced from PhosphoSitePlus.
                    </p>
                    <p className="m-0 mb-1 text-justify"> The functional annotation for each residue is presented in a table with 23 columns:</p>
                    <ul>
                    <li className="text-justify">The 'Position ' column indicates the residue position in the protein sequence.</li>
                    <li className="text-justify">The 'Ref' column contains the UniProt reference proteome residue.</li>
                    <li className="text-justify">The 'afRes' column contains the predicted AlphaFold residue.</li>
                    <li className="text-justify">The 'Natural Variants' column contains the UniProt annotated natural variants.</li>
                    <li className="text-justify">The 'Molecule Processing' column contains the UniProt annotated molecule processing (signal-peptide/transit-peptide/pro-peptide/chain).</li>
                    <li className="text-justify">The 'Location ' column contains the UniProt localization with respect to membranes.</li> unMotives
                    <li className="text-justify">The 'Motives ' column contains the UniProt annotated motives. </li>
                    <li className="text-justify">The 'Domains ' column contains the UniProt annotated domains. </li>
                    <li className="text-justify">The 'PFAM-Domain HMM Pos' column contains the PFAM domain and aligned position in the HMM.</li>
                    <li className="text-justify">The 'Binding Site' column contains the UniProt annotated ligands (binding site).</li>
                    <li className="text-justify">The 'Active Site' column contains the UniProt annotated properties (active site).</li>
                    <li className="text-justify">The 'PTMs' column contains the list of annotated Phosphosite PTMs.</li>
                    <li className="text-justify">The 'Glycosylations' column contains the UniProt annotated glycosylations.</li>
                    <li className="text-justify">The 'Lipidations' column contains the UniProt annotated lipidations.</li>
                    <li className="text-justify">The 'Modified Residues' column contains the UniProt annotated modified residues.</li>
                    <li className="text-justify">The 'Disulfide bong bridges' column contains the UniProt annotated disulphide-bond bridges.</li>
                    <li className="text-justify">The 'Secondary Structure' column contains the UniProt annotated secondary structure.</li>
                    <li className="text-justify">The 'AF-Predicted Secondary Structure' column contains the AlphaFold predicted secondary structure.</li>
                    <li className="text-justify">The 'AF-Confidence Value' column contains the AlphaFold confidence value.</li>
                    <li className="text-justify">The 'AF-Max Predicted IntRes' column contains the AlphaFold maximum predicted number of interacting residues in a single structure (r = 8A). </li>
                    <li className="text-justify">The 'AF-Predicted residues' column contains the AlphaFold list of residues with a predicted interaction in at least one structure (r = 8A).</li>
                    <li className="text-justify">The 'CrossLinks' column contains the UniProt annotated crosslinks.</li>
                    <li className="text-justify">The 'Binding Region' column contains the Binding region from IntAct.
                    The data is structured as follows: UniProt accession number of interactor A (binding region range),
                    UniProt accession number of interactor B (binding region range), interaction type, and MI score.
                    For instance: O00459(291-306)_P46108(124-198)_direct interaction(MI SCORE:0.4). </li>
                    </ul>

                     <GraphlqlQuery type={type} target={target} tab={activeTabIndex}/>

                    <p className="m-0 mb-1 text-justify">For more information, please refer to the
                     <Link className="" to="/about/" target="_blank"><b> about</b></Link> or <Link className="" to="/api/" target="_blank"><b> API</b></Link> page.
                     </p>
                </Dialog>
                <Tooltip target=".export-buttons>button" position="bottom" />
                { loadFunctionalInfo &&  (
                 <div className="row mb-4">
                    <ProgressSpinner style={{width: '50px', height: '50px'}}
                    strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                 </div>
                )}
              {(!loadFunctionalInfo) && (
                 <ResiduesTable ResidueData={data} />
               )}
            </div>
        </div>
        {/* Structure Section */}
         {(!loadFunctionalInfo) && (
            <div className="row mb-5">
              <h2 className="display-6 fw-bold mb-5">Structure</h2>
              <div className="col-md-2 mb-2">
                <div className="bg-light rounded-3">
                    <div className="px-4 py-4">
                        <div className="mb-2">
                            <label
                                    htmlFor="structure-color"
                                    className="form-label"
                            >
                                Annotation&nbsp;
                            </label>
                            <select
                                    className="form-select mb-2 outline-none"
                                    name="structure-color"
                                    value={annotation}
                                    onChange={handleAnnotation}
                            >
                                <option value="None">None</option>
                                <option value="pLDDT">pLDDT</option>
                                <option value="AlphaFill">AlphaFill</option>
                                <option value="surface">Accessible Surface Area</option>
                                <option value="hydrophobicity">Hydrophobicity</option>
                                <option value="name">Residue Name</option>
                                <option value="rainbow">Rainbow</option>
                                 <option value="unAnnNatVariants"
                                 value="unAnnNatVariants"
                                 disabled={data?.every(residue => residue.unAnnNatVariants.trim() === "no_variant")}
                                 >Natural variants</option>
                                 <option value="unMoleculeProcessing"
                                 value="unMoleculeProcessing"
                                 disabled={data?.every(residue => residue.unMoleculeProcessing.trim() === "unk")}
                                 >Molecule processing</option>
                                 <option value="unLocation"
                                 value="unLocation"
                                 disabled={data?.every(residue => residue.unLocation.trim() === "unk")}
                                 >Location</option>
                                 <option
                                 value="unMotives"
                                 disabled={data?.every(residue => residue.unMotives.trim() === "no_motive")}>Motives</option>
                                 <option value="unDomains"
                                 value="unDomains"
                                 disabled={data?.every(residue => residue.unDomains.trim() === "no_domain")}
                                 >Domains</option>
                                 <option value="interPro" disabled={domainInterPro?.length === 0 }>InterPro</option>
                                 <option value="pfamDomainPosHmm"
                                 value="pfamDomainPosHmm"
                                 disabled={data?.every(residue => residue.pfamDomainPosHmm.trim() === "no_PFAM_res")}
                                 >PFAM-HMM Pos</option>
                                 <option value="unLigandsBindingSite"
                                 value="unLigandsBindingSite"
                                 disabled={data?.every(residue => residue.unLigandsBindingSite.trim() === "no_ligand")}
                                 >Binding site</option>
                                 <option value="unAnnPropActiveSite"
                                 value="unAnnPropActiveSite"
                                 disabled={data?.every(residue => residue.unAnnPropActiveSite.trim() === "no_active_site")}
                                 >Active site</option>
                                 <option value="phosPtms"
                                 value="phosPtms"
                                 disabled={data?.every(residue => residue.phosPtms.trim() === "no_ptms")}
                                 >PTMs</option>
                                 <option value="unGlycosylations"
                                  value="unGlycosylations"
                                  disabled={data?.every(residue => residue.unGlycosylations.trim() === "no_carbohydrate")}
                                 >Glycosylations</option>
                                 <option value="unLipidations"
                                 value="unLipidations"
                                 disabled={data?.every(residue => residue.unLipidations.trim() === "no_lipid")}
                                 >Lipidations</option>
                                 <option value="unModifiedRes"
                                 value="unModifiedRes"
                                 disabled={data?.every(residue => residue.unModifiedRes.trim() === "no_mod_res")}
                                 >Modified Residue</option>
                                 <option value="unDisulfideBongBridges"
                                 value="unDisulfideBongBridges"
                                 disabled={data?.every(residue => residue.unDisulfideBongBridges.trim() === "no_bridge")}
                                 >Disulfide bond bridges</option>
                                 <option value="unSecondaryStructure"
                                  value="unSecondaryStructure"
                                  disabled={data?.every(residue => residue.unSecondaryStructure.trim() === "unk")}
                                 >Secondary Structure</option>
                                 <option value="afPredictedSecondaryStructure">AF-Predicted secondary structure</option>
                                 <option value="afResiduePredictedInteraction"
                                 value="afResiduePredictedInteraction"
                                  disabled={data?.every(residue => residue.afResiduePredictedInteraction.trim() === "no_af_residues")}
                                 >AF-Predicted residues</option>
                                 <option value="unCrossLinks"
                                  value="unCrossLinks"
                                  disabled={data?.every(residue => residue.unCrossLinks.trim() === "no_crosslink")}
                                 >CrossLinks</option>
                                 <option value="inBindingRegion"
                                  value="inBindingRegion"
                                  disabled={data?.every(residue => residue.inBindingRegion.trim() === "no_binding_region")}
                                 >Binding Region</option>
                            </select>
                            { (data && annotation) && (
                              <FunctionalAnnotationStructureLegend annotation={annotation}  colors={colors}  domainInterPro={domainInterPro}/>
                            )}

                            {(data && annotation === "AlphaFill" && ligandsOption.length > 0) && (
                             <div className="mb-2">
                               <label htmlFor="filters" className="form-label">Ligand&nbsp;</label>
                               <ListBox multiple value={selectedLigand} onChange={(e) => setSelectedLigand(e.value)}
                                value={selectedLigand} options={ligandsOption}
                                className="w-full md:w-14rem fs--2 text-justify list-filter mb-2" listStyle={{ maxHeight: '100px'}}/>

                                 <Button label="Unselect" icon="pi pi pi-refresh" className="fs--2 mb-1"
                                onClick={filterLigandsReset} />

                          </div>
                           )}
                           {( data && annotation === "AlphaFill" && ligandsOption.length === 0) && (
                             <div className="mb-2">
                               <label htmlFor="filters" className="form-label">Ligand&nbsp;</label>
                               <p className="fs--2 text-justify"> No results found</p>
                             </div>
                            )}
                            <p className="fs--2 text-justify">
                                Click on the viewer plugin icons to access more
                                advanced features from
                                <Link target="_blank" to="https://molstar.org/viewer-docs/">
                                <b> Mol*</b>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-9">
                <div className="viewer-box">
                    {data && (
                        <MolstarViewerFunctional
                            database="AF"
                            dataResidue={data}
                            annotation={annotation}
                            colors={colors}
                            ligands={selectedLigand}
                            setLigandsOption={setLigandsOption}
                            domainInterPro={domainInterPro}
                        />
                    )}
                </div>
            </div>
            {/* Summary Section */}
            { (data && annotation === "unAnnNatVariants") && (
                   <>
                       <Divider className="mb-4 mt-5"/>
                       <div className="row">
                           <h2 className="display-6 fw-bold mb-4">Summary</h2>
                           <div className="col-md-4">
                               <BarChartFunctional data={data} annotation={annotation} />
                           </div>
                       </div>
                   </>
                )}
            </div>
            )}
        </TabPanel>
         <TabPanel header="Intra-protein contacts">
         <h2 className="display-6 fw-bold mb-5">Intra-protein contacts<sup><Button icon="pi pi-info"
               onClick={() => showIntraDialog('top-right')} text size="small" className="btn-dialog"/></sup></h2>
               <Dialog header="Intra-protein contacts" visible={visibleIntraDialog}  style={{ width: '50vw' }}
               onHide={() => setVisibleIntraDialog(false)} position={positionIntraDialog}
                draggable={false} resizable={false}  breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <p className="m-0 mb-2 text-justify">
                    This section contains a list of intra-protein contacts obtained from experimental structures,
                    the table includes the UniProt accession number, the PDB ID of the 3D structure,
                    contact position, and the residue position in the protein sequence.
                    </p>
                     <GraphlqlQuery type={type} target={target} tab={activeTabIndex}/>

                    <p className="m-0 mb-1 text-justify">For more information, please refer to the
                     <Link className="" to="/about/" target="_blank"><b> about</b></Link> or <Link className="" to="/api/" target="_blank"><b> API</b></Link> page.
                     </p>
                </Dialog>
        { intraLoadData &&  (
         <div className="row mb-4">
            <ProgressSpinner style={{width: '50px', height: '50px'}}
            strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
         </div>
        )}
      {(!intraLoadData) && (
          <div className="row mb-4">
            <ContactTable contactData={intraContactsData} />
          </div>
         )}
            {(!intraLoadData && !intraLoadData && intraContactsData.length > 0 && uniqueIntraContactsPdbIds.length > 0) && (
              <div className="row mb-4">
                <h2 className="display-6 fw-bold mb-4">Structure</h2>
                <div className="col-md-2 mb-2">
                  <div className="bg-light rounded-3">
                    <div className="p-3">
                      <div className="mb-2">
                        <label htmlFor="structure" className="form-label">PDB ID&nbsp;</label>
                        <select className="form-select mb-3" name="structure-intra" value={structurePDBIdIntra} onChange={handlePDBidIntra}>
                          {uniqueIntraContactsPdbIds.map(pdbId => (
                            <option key={pdbId} value={pdbId.toUpperCase().trim()}
                            >{pdbId.toUpperCase().trim()}</option>
                          ))}
                        </select>
                         <div className="d-flex">
                            <div className="burble-contact"></div>
                            <p className="fs--2 text-justify">Contact position</p>
                         </div>
                        <p className="fs--2 text-justify">Click on the viewer plugin icons to access more advanced features from
                        <Link target="_blank" to="https://molstar.org/viewer-docs/"> Mol*</Link></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="viewer-box">
                    <MolstarViewer pdbId={structurePDBIdIntra}  tab="contacts" chain={chain} positions={positions} database={database}  uniAcc={uniprotAccessionNumber}/>
                  </div>
                </div>
              </div>
            )}
      </TabPanel>
      <TabPanel header="Inter-protein contacts">
        <h2 className="display-6 fw-bold mb-5">Inter-protein contacts<sup><Button icon="pi pi-info"
               onClick={() => showInterDialog('top-right')} text size="small" className="btn-dialog"/></sup></h2>
               <Dialog header="Inter-protein contacts" visible={visibleInterDialog} position={positionInterDialog} style={{ width: '50vw' }}
               onHide={() => setVisibleInterDialog(false)}
                draggable={false} resizable={false}  breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <p className="m-0 mb-2 text-justify">
                    This section contains a list of inter-protein contacts obtained from experimental structures,
                    the table includes the UniProt accession number, the PDB ID of the 3D structure,
                    contact position, and the residue position in the protein sequence.
                    These data offer insights into the complex networks of interactions underlying protein function and regulation.
                    </p>
                     <GraphlqlQuery type={type} target={target} tab={activeTabIndex}/>

                    <p className="m-0 mb-1 text-justify">For more information, please refer to the
                     <Link className="" to="/about/" target="_blank"><b> about</b></Link> or <Link className="" to="/api/" target="_blank"><b> API</b></Link> page.
                     </p>
                </Dialog>

        { interLoadData &&  (
          <div className="row mb-4">
            <ProgressSpinner style={{width: '50px', height: '50px'}}
            strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
           </div>
        )}

        { (!interLoadData) &&  (
        <div className="row mb-4">
            <ContactTable contactData={interContactsData} />
        </div>
        )}
        { (!interLoadData && interContactsData.length > 0 && uniqueInterContactsPdbIds.length > 0 ) && (
         <div className="row">
              <h2 className="display-6 fw-bold mb-4">Structure</h2>
                  <div className="col-md-2 mb-2">
                    <div className="bg-light rounded-3">
                      <div className="p-3">
                        <div className="mb-2">
                          <label htmlFor="structure" className="form-label">PDB ID&nbsp;</label>
                          <select className="form-select mb-2" name="structure-inter" value={structurePDBIdInter} onChange={handlePDBidInter}>
                            {uniqueInterContactsPdbIds.map(pdbId => (
                              <option key={pdbId} value={pdbId.toUpperCase().trim()}>{pdbId.toUpperCase().trim()}</option>
                            ))}
                          </select>
                           {uniqueInterContactsUniprotAccession.length > 1 && (
                             <div className="mb-2">
                             <label htmlFor="structure" className="form-label">UniProt Accession&nbsp;</label>
                            <select className="form-select mb-3" name="structure-inter" value={seqAccession} onChange={handleSeqAccession}>
                            {uniqueInterContactsUniprotAccession.map(seqUni => (
                              <option key={seqUni} value={seqUni.toUpperCase().trim()}>{seqUni.toUpperCase().trim()}</option>
                            ))}
                          </select>
                            </div>
                           )}
                           <div className="d-flex">
                            <div className="burble-contact"></div>
                            <p className="fs--2 text-justify">Contact position</p>
                           </div>
                          <p className="fs--2 text-justify">Click on the viewer plugin icons to access more advanced features from
                          <Link target="_blank" to="https://molstar.org/viewer-docs/"> Mol*</Link></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10">
                     <div className="viewer-box">
                           <MolstarViewer pdbId={structurePDBIdInter} tab="contacts" chain={chain} positions={positionsInter}  database={database} uniAcc={uniprotAccessionNumber}/>
                      </div>
                  </div>
         </div>
      )}
      </TabPanel>
      {(type === "gene" || type === "protein") &&  (
      <TabPanel header="Ligands">
        <h2 className="display-6 fw-bold mb-5">Ligands<sup><Button icon="pi pi-info"
               onClick={() => showLigand('top-right')} text size="small" className="btn-dialog"/></sup></h2>
               <Dialog header="Ligands" visible={visibleLigand} position={positionLigand} style={{ width: '50vw' }}
               onHide={() => setVisibleLigand(false)}
                draggable={false} resizable={false}  breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <p className="m-0 mb-2 text-justify">
                    Ligand interactions across the PDBe archive by including detailed information on interacting
                    macromolecule chains and the functional annotation of each ligand, such as cofactor-like,
                    drug-like, or reactant-like, and essential identifiers, such as InChIKey, bmID, and LigandType.
                    </p>
                     <GraphlqlQuery type={type} target={target} tab={activeTabIndex}/>

                    <p className="m-0 mb-1 text-justify">For more information, please refer to the
                     <Link className="" to="/about/" target="_blank"><b> about</b></Link> or <Link className="" to="/api/" target="_blank"><b> API</b></Link> page.
                     </p>
                </Dialog>

        { ligandLoadData &&  (
          <div className="row mb-4">
            <ProgressSpinner style={{width: '50px', height: '50px'}}
            strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
           </div>
        )}

        { (!ligandLoadData) &&  (
        <div className="row mb-4">
            <LigandTable ligandData={ligandData} />
        </div>
        )}
        { (!ligandLoadData && ligandData.length > 0 && uniqueLigandPdbIds.length > 0 ) && (
         <div className="row">
              <h2 className="display-6 fw-bold mb-4">Structure</h2>
                  <div className="col-md-2 mb-2">
                    <div className="bg-light rounded-3">
                      <div className="p-3">
                        <div className="mb-2">
                          <label htmlFor="structure" className="form-label">PDB ID&nbsp;</label>
                          <select className="form-select mb-2" name="structure-inter" value={structurePDBIdLigand} onChange={handlePDBidLigand}>
                            {uniqueLigandPdbIds.map(pdbId => (
                              <option key={pdbId} value={pdbId.toUpperCase().trim()}>{pdbId.toUpperCase().trim()}</option>
                            ))}
                          </select>
                          <p className="fs--2 text-justify">Click on the viewer plugin icons to access more advanced features from
                          <Link target="_blank" to="https://molstar.org/viewer-docs/"> Mol*</Link></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10">
                     <div className="viewer-box">
                           <MolstarViewer pdbId={structurePDBIdLigand} tab="ligands" database={database}/>
                      </div>
                  </div>
         </div>
      )}
      </TabPanel>
          )}
          <TabPanel header="ClinVar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;">
          <h2 className="display-6 fw-bold mb-5">ClinVar variants<sup><Button icon="pi pi-info"
               onClick={() => showVariant('top-right')} text size="small" className="btn-dialog"/></sup></h2>
               <Dialog header="Clinvar variants" visible={visibleVariant} position={positionVariant} style={{ width: '50vw' }}
               onHide={() => setVisibleVariant(false)}
                draggable={false} resizable={false}  breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                    <p className="m-0 mb-2 text-justify">
                      ClinVar variant annotations report the human variations classified for diseases for each residue.
                    </p>
                    <p>The variant annotation for each residue is presented in a table with 14 columns:</p>
                     <ul>
                    <li className="text-justify">The 'Position ' column indicates the residue position in the protein sequence.</li>
                    <li className="text-justify">The 'Location h19' column contains the location of the variants based on hg19.</li>
                    <li className="text-justify">The 'Location h38' column contains the location of the variants based on hg38.</li>
                    <li className="text-justify">The 'REF' column contains the reference allele.</li>
                    <li className="text-justify">The 'ATL' column contains the alternative allele.</li>
                    <li className="text-justify">The 'Variant ID' column contains the identifier(s) of co-located known variants.</li>
                    <li className="text-justify">The 'Consequence' column contains the consequence type.</li>
                    <li className="text-justify">The 'Clinical significance' column contains the ClinVar clinical significance of the dbSNP variant.</li>
                    <li className="text-justify">The 'Disease' column contains the ClinVar-associated diseases.</li>
                    <li className="text-justify">The 'Protein position' column contains the relative position of amino acid in protein.</li>
                    <li className="text-justify">The 'AA change' column contains the reference and variant amino acids.</li>
                    <li className="text-justify">The 'Codon' column contains the reference and variant codon sequence.</li>
                    <li className="text-justify">The 'AlphaMissense score' column contains the predicted score of AlphaMissense, an amino acid level predictor of pathogenicity.</li>
                    <li className="text-justify">The 'AlphaMissense class' column contains the predicted class of AlphaMissense, an amino acid level predictor of pathogenicity.</li>
                    </ul>

                     <GraphlqlQuery type={type} target={target} tab={activeTabIndex}/>

                    <p className="m-0 mb-1 text-justify">For more information, please refer to the
                     <Link className="" to="/about/" target="_blank"><b> about</b></Link> or <Link className="" to="/api/" target="_blank"><b> API</b></Link> page.
                     </p>
                </Dialog>
        {variantLoadData &&  (
          <div className="row mb-4">
            <ProgressSpinner style={{width: '50px', height: '50px'}}
            strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
           </div>
        )}
        { variantData.length > 50  && (
                  <div className="row mb-4">
                       <Message className="col-md-12 ml-left"
                       text="Our results show over 50 variants. Select a variant by clicking on the table row to view it in the structure view. You are unable to use the filter." />
                  </div>
        )}

        { (!variantLoadData) &&  (
        <div className="row mb-4">
            <VariantTable VariantData={variantData}  setPositionSummary={setPositionSummary}  setSelectedVariants={setSelectedVariants} setUniprotAccessionNumber={setUniprotAccessionNumber} />
        </div>
        )}
        { (!variantLoadData && variantData.length > 0 && uniqueVariantPdbIds.length > 0 ) && (
         <div className="row">
              <h2 className="display-6 fw-bold mb-4">Structure</h2>
              { (positionsVar.length === 0 && selectedVariants.length !== 0 ) && (
                  <div className="row mb-4">
                       <Message className="col-md-12 ml-left"
                       text="No variants were mapped to this structure due to the structure's lack of coverage of the sequence position." />
                  </div>
              )}
                  <div className="col-md-2 mb-2">
                    <div className="bg-light rounded-3">
                      <div className="p-3">
                        <div className="mb-2">
                          <label htmlFor="structure" className="form-label">Structure&nbsp;</label>
                          <select
                              className="form-select mb-3"
                              name="structure-inter"
                              value={structurePDBIdVariant}
                              onChange={handlePDBidVariant}>
                              {uniqueVariantPdbIds.map((pdbId, index) => {
                                // Ensure pdbId is a string and handle cases where it's undefined or not a string
                                const validPdbId = typeof pdbId === 'string' ? pdbId.toUpperCase().trim() : String(pdbId || "").toUpperCase().trim();
                                return (
                                  <option key={index} value={validPdbId} >
                                    {validPdbId}
                                  </option>
                                );
                              })}
                            </select>
                          <div className="d-flex">
                            <div className="burble-variant"></div>
                            <p className="fs--2 text-justify">Variant position</p>
                           </div>
                           <div className="mb-2">
                               <label htmlFor="filters" className="form-label">Filters&nbsp;</label>
                               <ListBox multiple value={selectedFilter} onChange={(e) => setSelectedFilter(e.value)}
                                options={groupedFilters} optionLabel="label"
                                optionGroupLabel="label" optionGroupChildren="items" optionGroupTemplate={groupTemplate}
                                className="w-full md:w-14rem fs--2 text-justify list-filter mb-2" listStyle={{ maxHeight: '100px'}}
                                disabled={variantData.length > 50 || selectedVariants.length > 0 }/>

                                <Button label="Filter" icon="pi pi-filter" className="fs--2 mb-1 me-1" loading={loading}
                                onClick={filterVariant} disabled={variantData.length > 50 || selectedVariants.length > 0 } />

                                <Button label="Clear filter" icon="pi pi-filter-slash" className="fs--2 mb-1"
                                onClick={filterVariantReset} disabled={variantData.length > 50 || selectedVariants.length > 0 }/>

                          </div>
                          <p className="fs--2 text-justify">Click on the viewer plugin icons to access more advanced features from
                          <Link target="_blank" to="https://molstar.org/viewer-docs/"> Mol*</Link></p>
                           <p className="fs--2 text-justify">*Some table variants cannot be mapped to the PDB structure due to the structure's
                            lack of coverage of the sequence position.</p>
                            <p className="fs--2 text-justify">*Select specific variants by clicking on the table row to view them in the structure view. Filtering is not available.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 mb-3">
                     <div className="viewer-box">
                           <MolstarViewer pdbId={structurePDBIdVariant} tab="variants" positions={positionsVar} chain={chain} database={database}/>
                      </div>
                  </div>
                  <Divider className="mb-4"/>
              <div className="row">
                  <h2 className="display-6 fw-bold mb-4">Summary</h2>
                  { ( selectedVariants.length === 0 && !positionSummary )  && (
                     <p className="text-justify mb-3">Top 10 Residues Summary: This list ranks the top 10 residues based on the number of variants for each</p>
                  )}
                  { (selectedVariants.length === 1 || positionSummary)  && (
                     <p className="text-justify mb-3">Residues Summary: This ranks the five residues before and after the selected position and shows the number of variants for each</p>
                  )}
                  { selectedVariants.length > 1  && (
                     <p className="text-justify mb-3">Residues Summary: This shows the number of variants for each selected residue position</p>
                  )}
                  <div className="row">
                  <div className="col-md-2 mb-2">
                    <div className="bg-light rounded-3">
                      <div className="p-3">
                        <div className="mb-2">
                          <label htmlFor="structure" className="form-label">Variant position&nbsp;</label>
                            <VariantSelect  selectedVariants={selectedVariants}  variantData={variantData} positionSummary={positionSummary}  handleVariantPosition={handleVariantPosition} />
                          </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="col-md-4">
                  <BarChartVariant title="Consequence" VariantData={variantData} consequence={itemsConsequence} clinical={itemsClinical} aa={itemsAAClass}  selectedVariants={selectedVariants} positionSummary={positionSummary}/>
                  </div>
                  <div className="col-md-4">
                  <BarChartVariant title="Clinical Significance" VariantData={variantData} consequence={itemsConsequence} clinical={itemsClinical} aa={itemsAAClass} selectedVariants={selectedVariants} positionSummary={positionSummary}/>
                  </div>
                  <div className="col-md-4">
                  <BarChartVariant title="AlphaMissense class" VariantData={variantData} consequence={itemsConsequence} clinical={itemsClinical} aa={itemsAAClass} selectedVariants={selectedVariants} positionSummary={positionSummary}/>
                  </div>
             </div>
                 </div>
              )}

          </TabPanel>
        </TabView>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ResultPage;
