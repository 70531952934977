import React, { useEffect, useRef, useMemo } from 'react';
import { createPluginUI } from 'molstar/lib/mol-plugin-ui';
import { ColorNames } from 'molstar/lib/mol-util/color/names';
import 'molstar/lib/mol-plugin-ui/skin/light.scss';
import { renderReact18 } from 'molstar/lib/mol-plugin-ui/react18';
import { PluginCommands } from 'molstar/lib/mol-plugin/commands';
import { Script } from 'molstar/lib/mol-script/script';
import { StructureSelection } from 'molstar/lib/mol-model/structure/query';
import { MolScriptBuilder as MS, MolScriptBuilder } from 'molstar/lib/mol-script/language/builder';
import { StateTransforms } from 'molstar/lib/mol-plugin-state/transforms';
import { createStructureRepresentationParams } from 'molstar/lib/mol-plugin-state/helpers/structure-representation-params';
import { Color } from "molstar/lib/mol-util/color";
import { Structure, StructureElement, StructureProperties } from 'molstar/lib/mol-model/structure';
import { MarkerAction, applyMarkerAction } from 'molstar/lib/mol-util/marker-action';
import { setStructureTransparency } from 'molstar/lib/mol-plugin-state/helpers/structure-transparency';
import { setSubtreeVisibility } from 'molstar/lib/mol-plugin/behavior/static/state';

// Show contact mapping in the sequence panel
async function itemsColor(positions) {
  // Function to apply colors to contacts
  function applyColors() {

    if (positions) {

      positions.forEach(position => {

        // Get the new position for each contact in the array
        const new_position = parseInt(position) - 1;

        // Get the corresponding span element for the current item
        const span = document.querySelector(`span[data-seqid="${new_position}"]`);

        // Find the select element
        const selectElement = document.querySelector('select.msp-form-control:nth-child(5)');

        if (selectElement) {
          // Set the sequence index in the sequence panel
          const initialValue = "0|" + 1;
          selectElement.value = initialValue;

          // Create a new 'change' event
          const event = new Event('change', { bubbles: true });

          // Dispatch the 'change' event on the select element
          selectElement.dispatchEvent(event);

          // Disable the select field
          selectElement.disabled = true;
        }

        // Apply the CSS class "highlight" if the span element exists
        if (span) {
          span.classList.add('highlight');
        }
      });
    }
  }

  // Function to check if the element is present on the page
  function checkElementPresence() {
    const element = document.querySelector('.msp-sequence-number');
    return !!element;
  }

  // Observer for changes in the DOM
  const observer = new MutationObserver((mutationsList, observer) => {
    if (checkElementPresence()) {
      // If the element is present, apply colors and disconnect the observer
      applyColors();
      observer.disconnect();
    }
  });

  // Observer configuration
  const config = { childList: true, subtree: true };

  // Start the observer
  observer.observe(document.body, config);

  // Check initially if the element is already present
  if (checkElementPresence()) {
    applyColors();
    observer.disconnect();
  }
}

const MolstarViewerFunctional = ({ annotation, dataResidue, database, colors, ligands, setLigandsOption, domainInterPro }) => {

  const viewerRef = useRef(null);
  const pluginInstanceRef = useRef(null);

  const uniac = dataResidue[0].anPosition.split("_")[0];

  const initMolstar = useMemo(() => async () => {
    if (viewerRef.current && !pluginInstanceRef.current) {
      const plugin = await createPluginUI({
        target: viewerRef.current,
        layoutIsExpanded: false,
        layoutShowControls: false,
        layoutShowRemoteState: false,
        layoutShowSequence: true,
        layoutShowLog: false,
        layoutShowLeftPanel: false,
        viewportShowExpand: false,
        viewportShowSelectionMode: false,
        viewportShowAnimation: false,
        backgroundColor: 'white',
        render: renderReact18,
      });
      pluginInstanceRef.current = plugin;

      try {

       // Set structure database
       const urlStructure = database === 'AF' && annotation !== 'AlphaFill'
          ? `https://alphafold.ebi.ac.uk/files/AF-${uniac}-F1-model_v4.cif`
          : `https://alphafill.eu/v1/aff/${uniac}`;

      const data = await pluginInstanceRef.current.builders.data.download(
          { url: urlStructure },
          { state: { isGhost: true } }
        );

        const trajectory = await pluginInstanceRef.current.builders.structure.parseTrajectory(data, 'mmcif');
        const structure = await pluginInstanceRef.current.builders.structure.hierarchy.applyPreset(
          trajectory,
          'default'
          , {
            showUnitcell: false,
            representationPreset: 'auto',
        }
        );

        // Change background color
        const renderer = plugin.canvas3d.props.renderer;
        PluginCommands.Canvas3D.SetSettings(plugin, { settings: { renderer: { ...renderer, backgroundColor: ColorNames.white } } });

        const dta = plugin.managers.structure.hierarchy.current.structures[0]?.cell.obj?.data;
        if (!dta) return;


        // Get polymer representation
        const cartoon = structure.representation.representations.polymer;

        const chemicalComponentMap = dta.model.properties.chemicalComponentMap;
        const amino_acid = ["ALA", "ARG", "ASN", "ASP", "CYS", "GLN", "GLU", "GLY", "HIS", "ILE",
                            "LEU", "LYS", "MET", "PHE", "PRO", "SER", "TRP", "THR", "TYR", "VAL"];

        const keysNotInAminoAcid = Array.from(chemicalComponentMap.keys()).filter(key => !amino_acid.includes(key));

        setLigandsOption(prevLigandsOption => {
          const isDifferent = JSON.stringify(prevLigandsOption) !== JSON.stringify(keysNotInAminoAcid);
          return isDifferent ? keysNotInAminoAcid : prevLigandsOption;
        });


       if (annotation === "unDomains"){

           const residueDta = dataResidue
            .filter(residue => residue.unDomains.trim() !== "no_domain")
            .map(residue => ({
                unDomains: residue.unDomains,
                anPosition: parseInt(residue.anPosition.split("_")[1], 10)
            }));

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: ColorNames.white }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < residueDta.length; i++) {
                let start = parseInt(residueDta[i].anPosition);
                let stop = parseInt(residueDta[i].anPosition);
                let color = colors[residueDta[i].unDomains];

                params.layers.push({
                    script: Script(
                          `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (in-range atom.resno ${start} ${stop} )
                          )`,
                        language
                    ),
                    color: Color("0x" + color),
                    clear: false
                });
            }

            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            await update.commit();

        } else if (annotation === "pLDDT" ){

           const MyProvider = {
              label: (loci) => {
                if (StructureElement.Loci.is(loci)) {
                  const loc = StructureElement.Loci.getFirstLocation(loci);
                  if (!loc) return;
                  const bFactor = StructureProperties.atom.B_iso_or_equiv(loc);
                  return `pLDDT: ${bFactor}`;
                }
                return;
              },
             };

             // Create and apply custom representation
            const reprParamsStructurePlddtColor = createStructureRepresentationParams(plugin, undefined, {
                type: "cartoon",
                color: "uncertainty",
                colorParams: {
                  value: 1,
                  domain: [0, 100],
                  list: {
                    colors: [
                      Color(0x0053d6),
                      Color(0x65cbf3),
                      Color(0xffdb13),
                      Color(0xff7d45),
                      Color(0xffffff)
                    ],
                  },
                },
                size: "uniform",
            });

            const updateTheme = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructurePlddtColor);

            plugin.managers.lociLabels.addProvider(MyProvider);

            await updateTheme.commit();

        } else if (annotation === "afPredictedSecondaryStructure"){

             // Create and apply custom representation
            const reprParamsStructureGaussian = createStructureRepresentationParams(plugin, undefined, {
               type: "cartoon",
               color: 'secondary-structure',
               size: "uniform"
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureGaussian);

            await update.commit();

        } else if (annotation === "unAnnNatVariants"){

           const residueDta = dataResidue
                    .filter(residue => residue.unAnnNatVariants.trim() !== "no_variant")
                    .map(residue => ({
                        variant: residue.unAnnNatVariants.trim(),
                        anPosition: parseInt(residue.anPosition.split("_")[1], 10)
                    }));

            // Extract unique positions
            const uniquePositions = [...new Set(residueDta.map(r => r.anPosition))];

            // Select multiples residues in the sequence
            const selection = Script.getStructureSelection(Q => Q.struct.generator.atomGroups({
            "chain-test": Q.core.rel.eq([MolScriptBuilder.struct.atomProperty.macromolecular.auth_asym_id(), "A"]),
            'residue-test': Q.core.set.has([Q.set(...uniquePositions), Q.ammp('auth_seq_id')]),
            'atom-test': MS.core.set.has([MS.set('CA'), MS.ammp('label_atom_id')]), // Get only carbon alpha
            'group-by': Q.struct.atomProperty.macromolecular.residueKey(),}), dta);

            // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

            // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA")}
            });

            // Create and apply custom representation - Show residue as spacefill (Displays atomic/coarse elements as spheres)
            const reprParams = createStructureRepresentationParams(plugin, undefined, {
              type: 'spacefill',
              color: 'uniform',
              colorParams: { value: Color(0xc93c20) },
              size: 'physical',
              sizeParams: { scale: 1 }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            const update2 = plugin
            .build()
            .to(cartoon)
             .apply(StateTransforms.Model.StructureSelectionFromExpression, { label: 'Surroundings', expression: selection })
             .apply(StateTransforms.Representation.StructureRepresentation3D, reprParams);

             // Custom Label Provider
                const MyProvider = {
                    label: (loci) => {
                        if (StructureElement.Loci.is(loci)) {
                            const loc = StructureElement.Loci.getFirstLocation(loci);
                            if (!loc) return;

                            const variant_position = StructureProperties.residue.auth_seq_id(loc);

                            // Check if the variant_position matches anPosition in residueDta
                            const matchingResidue = residueDta.find(res => res.anPosition === variant_position);
                            if (matchingResidue) {
                                let variantLabel = matchingResidue.variant;

                                // Determine the type of variant and append the label
                                if (variantLabel.includes("VAR")) {
                                    variantLabel += " (Missense)";
                                } else if (variantLabel.includes("del")) {
                                    variantLabel += " (Deletion)";
                                } else if (variantLabel.includes("I")) {
                                    variantLabel += " (Insertion)";
                                }

                                    return `Variant: ${variantLabel}`;
                            }

                            return ``;
                        }
                        return;
                    },
                };

            plugin.managers.lociLabels.addProvider(MyProvider);

            await update.commit();
            await update2.commit();

        } else if (annotation === "unMotives"){

           const residueDta = dataResidue
                    .filter(residue => residue.unMotives.trim() !== "no_motive")
                     .map(residue => ({
                        motives: residue.unMotives.trim(),
                        anPosition: parseInt(residue.anPosition.split("_")[1], 10)
                    }));

            // Extract unique positions
            const positions = [...new Set(residueDta.map(r => r.anPosition))];

           // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < positions.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (set.has (set ${positions[i]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0x008000),
                    clear: false
                });
            }

            // Apply yellow color
            const update2 = plugin.build();
            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            await update.commit();
            await update2.commit();

            await itemsColor(positions);

            // Custom Label Provider
            const MyProvider = {
                label: (loci) => {
                    if (StructureElement.Loci.is(loci)) {
                        const loc = StructureElement.Loci.getFirstLocation(loci);
                        if (!loc) return;

                        const motives_position = StructureProperties.residue.auth_seq_id(loc);

                        // Check if the variant_position matches anPosition in residueDta
                        const matchingResidue = residueDta.find(res => res.anPosition === motives_position);
                        if (matchingResidue) {
                            return `Motive: ${matchingResidue.motives}`;
                        }

                        return ``;
                    }
                    return;
                },
            };

            plugin.managers.lociLabels.addProvider(MyProvider);

        } else if (annotation === "pfamDomainPosHmm"){

           const residueDta = dataResidue
                    .filter(residue => residue.pfamDomainPosHmm.trim() !== "no_PFAM_res")
                    .map(residue => (parseInt(residue.anPosition.split("_")[1])));

           const positions = [...new Set(residueDta)];

           // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < positions.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (set.has (set ${positions[i]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0x008000),
                    clear: false
                });
            }

            // Apply yellow color
            const update2 = plugin.build();
            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            await update.commit();
            await update2.commit();

        } else if (annotation === "unLigandsBindingSite"){

           const residueDta = dataResidue
                    .filter(residue => residue.unLigandsBindingSite.trim() !== "no_ligand")
                    .map(residue => ({
                        ligand: residue.unLigandsBindingSite.trim(),
                        anPosition: parseInt(residue.anPosition.split("_")[1], 10)
                    }));

            // Extract unique positions
            const positions = [...new Set(residueDta.map(r => r.anPosition))];

           // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < positions.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (set.has (set ${positions[i]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0x008000),
                    clear: false
                });
            }

            // Apply yellow color
            const update2 = plugin.build();
            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);


            // Custom Label Provider
            const MyProvider = {
                label: (loci) => {
                    if (StructureElement.Loci.is(loci)) {
                        const loc = StructureElement.Loci.getFirstLocation(loci);
                        if (!loc) return;

                        const ligand_position = StructureProperties.residue.auth_seq_id(loc);

                        // Check if the ligand_position matches anPosition in residueDta
                        const matchingResidue = residueDta.find(res => res.anPosition === ligand_position);
                        if (matchingResidue) {
                            return `Ligand: ${matchingResidue.ligand}`;
                        }

                        return ``;
                    }
                    return;
                },
            };

            plugin.managers.lociLabels.addProvider(MyProvider);

            await update.commit();
            await update2.commit();

            await itemsColor(positions);

        }  else if (annotation === "unAnnPropActiveSite"){

           const residueDta = dataResidue
                    .filter(residue => residue.unAnnPropActiveSite.trim() !== "no_active_site")
                    .map(residue => ({
                        active: residue.unAnnPropActiveSite.trim(),
                        anPosition: parseInt(residue.anPosition.split("_")[1], 10)
                    }));

            // Extract unique positions
            const positions = [...new Set(residueDta.map(r => r.anPosition))];

           // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < positions.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (set.has (set ${positions[i]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0x008000),
                    clear: false
                });
            }

            // Apply yellow color
            const update2 = plugin.build();
            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            // Custom Label Provider
            const MyProvider = {
                label: (loci) => {
                    if (StructureElement.Loci.is(loci)) {
                        const loc = StructureElement.Loci.getFirstLocation(loci);
                        if (!loc) return;

                        const active_position = StructureProperties.residue.auth_seq_id(loc);

                        // Check if the ligand_position matches anPosition in residueDta
                        const matchingResidue = residueDta.find(res => res.anPosition === active_position);
                        if (matchingResidue) {
                            return `Active site: ${matchingResidue.active}`;
                        }

                        return ``;
                    }
                    return;
                },
            };

            plugin.managers.lociLabels.addProvider(MyProvider);

            await update.commit();
            await update2.commit();

            await itemsColor(positions);

        } else if (annotation === "phosPtms") {

              const residueDta = dataResidue
                    .filter(residue => residue.phosPtms.trim() !== "no_ptms")
                    .map(residue => ({
                        ptm: residue.phosPtms.trim(),
                        anPosition: parseInt(residue.anPosition.split("_")[1], 10)
                    }));

                // Get polymer representation
                const cartoon = structure.representation.representations.polymer;

                // Create and apply custom representation to reset structure color
                const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
                    type: 'cartoon',
                    color: 'uniform',
                    colorParams: { value: Color("0XFAFAFA") }
                });

                const update = plugin
                    .build()
                    .to(cartoon)
                    .update(reprParamsStructureResetColor);

                // Set script language
                const language = 'mol-script';

                // Create params object for structure representation
                const params = { layers: [] };

                // Define color map
                const colorMap = { 'ub': 'bf812d', 'ac': '5aae61', 'p': '4393C3', 'other': '666666' };
                const multiPtmColor = "66D8C9"; // Color for multiple PTM values

                // Add layers to params object and apply color to span elements
                residueDta.forEach(({ ptm, anPosition }) => {
                    const start = anPosition;
                    const stop = anPosition;

                    // Determine color based on PTM values
                    const ptmValues = ptm.split(" ");
                    let color;
                    let ptmClass;

                    if (ptmValues.length > 1) {
                        color = multiPtmColor;
                         ptmClass = 'multi-ptm';
                    } else {
                        const singlePtm = ptmValues[0].split("-")[0];
                        color = colorMap[singlePtm] || colorMap['other'];
                        ptmClass = singlePtm || 'other';
                    }

                    // Apply color to structure
                    params.layers.push({
                        script: Script(
                            `(sel.atom.atom-groups
                                 :chain-test (= atom.auth_asym_id A )
                                 :residue-test (in-range atom.resno ${start} ${stop} )
                              )`,
                            language
                        ),
                        color: Color("0x" + color),
                        clear: false
                    });

                     // Apply PTM-specific class to the corresponding span element
                     const span = document.querySelector(`span[data-seqid="${anPosition - 1}"]`);
                     if (span) {
                            span.classList.add(ptmClass);
                     }
                });

                // Apply the structural representation with color updates
                const update2 = plugin.build();
                update
                    .to(cartoon)
                    .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

                // Custom Label Provider
                const MyProvider = {
                    label: (loci) => {
                        if (StructureElement.Loci.is(loci)) {
                            const loc = StructureElement.Loci.getFirstLocation(loci);
                            if (!loc) return;

                            const ptm_position = StructureProperties.residue.auth_seq_id(loc);

                            // Check if the ptm_position matches anPosition in residueDta
                            const matchingResidue = residueDta.find(res => res.anPosition === ptm_position);
                            if (matchingResidue) {
                                return `PTMs: ${matchingResidue.ptm}`;
                            }

                            return ``;
                        }
                        return;
                    },
                };

                plugin.managers.lociLabels.addProvider(MyProvider);

                await update.commit();
                await update2.commit();
            }
            else if (annotation === "unGlycosylations"){

           const residueDta = dataResidue
                    .filter(residue => residue.unGlycosylations.trim() !== "no_carbohydrate")
                    .map(residue => ({
                        glycosylation: residue.unGlycosylations.trim(),
                        anPosition: parseInt(residue.anPosition.split("_")[1], 10)
                    }));

            // Extract unique positions
            const positions = [...new Set(residueDta.map(r => r.anPosition))];

           // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < positions.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (set.has (set ${positions[i]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0x008000),
                    clear: false
                });
            }

            // Apply yellow color
            const update2 = plugin.build();
            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            // Custom Label Provider
            const MyProvider = {
                    label: (loci) => {
                        if (StructureElement.Loci.is(loci)) {
                            const loc = StructureElement.Loci.getFirstLocation(loci);
                            if (!loc) return;

                            const glycosylation_position = StructureProperties.residue.auth_seq_id(loc);

                            // Check if the ptm_position matches anPosition in residueDta
                            const matchingResidue = residueDta.find(res => res.anPosition === glycosylation_position);
                            if (matchingResidue) {
                                return `Glycosylation: ${matchingResidue.glycosylation}`;
                            }

                            return ``;
                        }
                        return;
                    },
                };

                plugin.managers.lociLabels.addProvider(MyProvider);

            await update.commit();
            await update2.commit();

            await itemsColor(positions);

        } else if (annotation === "unLipidations"){

           const residueDta = dataResidue
                    .filter(residue => residue.unLipidations.trim() !== "no_lipid")
                    .map(residue => ({
                        lipidations: residue.unLipidations.trim(),
                        anPosition: parseInt(residue.anPosition.split("_")[1], 10)
                    }));

            // Extract unique positions
            const positions = [...new Set(residueDta.map(r => r.anPosition))];

           // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < positions.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (set.has (set ${positions[i]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0x008000),
                    clear: false
                });
            }

            // Apply yellow color
            const update2 = plugin.build();
            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            // Custom Label Provider
             const MyProvider = {
                    label: (loci) => {
                        if (StructureElement.Loci.is(loci)) {
                            const loc = StructureElement.Loci.getFirstLocation(loci);
                            if (!loc) return;

                            const lipidations_position = StructureProperties.residue.auth_seq_id(loc);

                            // Check if the ptm_position matches anPosition in residueDta
                            const matchingResidue = residueDta.find(res => res.anPosition === lipidations_position);
                            if (matchingResidue) {
                                return `Lipidation: ${matchingResidue.lipidations}`;
                            }

                            return ``;
                        }
                        return;
                    },
                };

                plugin.managers.lociLabels.addProvider(MyProvider);

            await update.commit();
            await update2.commit();

            await itemsColor(positions);

        } else if (annotation === "unModifiedRes"){

           const residueDta = dataResidue
                    .filter(residue => residue.unModifiedRes.trim() !== "no_mod_res")
                    .map(residue => ({
                        mod_res: residue.unModifiedRes.trim(),
                        anPosition: parseInt(residue.anPosition.split("_")[1], 10)
                    }));

            // Extract unique positions
            const positions = [...new Set(residueDta.map(r => r.anPosition))];

           // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < positions.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (set.has (set ${positions[i]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0x008000),
                    clear: false
                });
            }

            // Apply yellow color
            const update2 = plugin.build();
            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            // Custom Label Provider
             const MyProvider = {
                    label: (loci) => {
                        if (StructureElement.Loci.is(loci)) {
                            const loc = StructureElement.Loci.getFirstLocation(loci);
                            if (!loc) return;

                            const mod_res_position = StructureProperties.residue.auth_seq_id(loc);

                            // Check if the mod_res matches anPosition in residueDta
                            const matchingResidue = residueDta.find(res => res.anPosition === mod_res_position);
                            if (matchingResidue) {
                                return `Modified Residue: ${matchingResidue.mod_res}`;
                            }

                            return ``;
                        }
                        return;
                    },
                };

                plugin.managers.lociLabels.addProvider(MyProvider);


            await update.commit();
            await update2.commit();

            await itemsColor(positions);

        } else if (annotation === "unDisulfideBongBridges"){

           const residueDta = dataResidue
                    .filter(residue => residue.unDisulfideBongBridges.trim() !== "no_bridge")
                    .map(residue => ({
                        bond: residue.unDisulfideBongBridges.trim(),
                        anPosition: parseInt(residue.anPosition.split("_")[1], 10)
                    }));

            // Extract unique positions
            const positions = [...new Set(residueDta.map(r => r.anPosition))];

           // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < positions.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (set.has (set ${positions[i]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0x008000),
                    clear: false
                });
            }

            // Apply yellow color
            const update2 = plugin.build();
            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);


            // Custom Label Provider
             const MyProvider = {
                    label: (loci) => {
                        if (StructureElement.Loci.is(loci)) {
                            const loc = StructureElement.Loci.getFirstLocation(loci);
                            if (!loc) return;

                            const bond_position = StructureProperties.residue.auth_seq_id(loc);

                            // Check if the mod_res matches anPosition in residueDta
                            const matchingResidue = residueDta.find(res => res.anPosition === bond_position);
                            if (matchingResidue) {
                                return `Disulfide bond bridge: ${matchingResidue.bond}`;
                            }

                            return ``;
                        }
                        return;
                    },
                };

                plugin.managers.lociLabels.addProvider(MyProvider);

            await update.commit();
            await update2.commit();

            await itemsColor(positions);

        } else if (annotation === "unCrossLinks"){

           const residueDta = dataResidue
                    .filter(residue => residue.unCrossLinks.trim() !== "no_crosslink")
                    .map(residue => ({
                        cross: residue.unCrossLinks.trim(),
                        anPosition: parseInt(residue.anPosition.split("_")[1], 10)
                    }));

            // Extract unique positions
            const positions = [...new Set(residueDta.map(r => r.anPosition))];

           // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < positions.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (set.has (set ${positions[i]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0x008000),
                    clear: false
                });
            }

            // Apply yellow color
            const update2 = plugin.build();
            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

             // Custom Label Provider
             const MyProvider = {
                    label: (loci) => {
                        if (StructureElement.Loci.is(loci)) {
                            const loc = StructureElement.Loci.getFirstLocation(loci);
                            if (!loc) return;

                            const cross_position = StructureProperties.residue.auth_seq_id(loc);

                            // Check if the mod_res matches anPosition in residueDta
                            const matchingResidue = residueDta.find(res => res.anPosition === cross_position);
                            if (matchingResidue) {
                                return `CrossLink: ${matchingResidue.cross}`;
                            }

                            return ``;
                        }
                        return;
                    },
                };

                plugin.managers.lociLabels.addProvider(MyProvider);

            await update.commit();
            await update2.commit();

            await itemsColor(positions);

        } else if (annotation === "inBindingRegion"){

           const residueDta = dataResidue
                    .filter(residue => residue.inBindingRegion !== "no_binding_region")
                    .map(residue => ({
                        region: residue.inBindingRegion.trim(),
                        anPosition: parseInt(residue.anPosition.split("_")[1], 10)
                    }));

            // Extract unique positions
            const positions = [...new Set(residueDta.map(r => r.anPosition))];

           // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < positions.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (set.has (set ${positions[i]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0x008000),
                    clear: false
                });
            }

            // Apply yellow color
            const update2 = plugin.build();
            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

             // Custom Label Provider
             const MyProvider = {
                    label: (loci) => {
                        if (StructureElement.Loci.is(loci)) {
                            const loc = StructureElement.Loci.getFirstLocation(loci);
                            if (!loc) return;

                            const bind_position = StructureProperties.residue.auth_seq_id(loc);

                            // Check if the mod_res matches anPosition in residueDta
                            const matchingResidue = residueDta.find(res => res.anPosition === bind_position);
                            if (matchingResidue) {
                                return `Binding Region: ${matchingResidue.region}`;
                            }

                            return ``;
                        }
                        return;
                    },
                };

                plugin.managers.lociLabels.addProvider(MyProvider);

            await update.commit();
            await update2.commit();

            await itemsColor(positions);

        }  else if (annotation === "unSecondaryStructure"){

           const residueDta = dataResidue
            .filter(residue => residue.unSecondaryStructure.trim() !== "unk")
            .map(residue => ({
                sStructure: residue.unSecondaryStructure.trim(),
                anPosition: parseInt(residue.anPosition.split("_")[1], 10)
            }));

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: ColorNames.white }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            const colorMap = {'HELIX': '838ffc', 'STRAND': 'da54a3'};

            // Add layers to params object
            for (let i = 0; i < residueDta.length; i++) {
                let start = parseInt(residueDta[i].anPosition);
                let stop = parseInt(residueDta[i].anPosition);
                let color = colorMap[residueDta[i].sStructure];
                params.layers.push({
                    script: Script(
                          `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (in-range atom.resno ${start} ${stop} )
                          )`,
                        language
                    ),
                    color: Color("0x" + color),
                    clear: false
                });
            }

            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            await update.commit();

        } else if (annotation === "afResiduePredictedInteraction") {

           const residueDta = dataResidue
            .filter(residue => residue.afResiduePredictedInteraction.trim() !== "no_af_residues")
            .map(residue => [...new Set(residue.afResiduePredictedInteraction.split(" "))]);

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: ColorNames.white }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < residueDta.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (set.has (set ${residueDta[i][0]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0x008000),
                    clear: false
                });
            }

            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            await update.commit();

        } else if (annotation === "unLocation"){

           const residueDta = dataResidue
            .filter(residue => residue.unLocation.trim() !== "unk")
            .map(residue => ({
                location: residue.unLocation.trim(),
                anPosition: parseInt(residue.anPosition.split("_")[1], 10)
            }));

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: ColorNames.white }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            const colorMap = {'EXTRAMEM': '838ffc', 'TRANSMEM': 'da54a3', 'INTRAMEM': '820e44'};

            // Add layers to params object
            for (let i = 0; i < residueDta.length; i++) {
                let start = parseInt(residueDta[i].anPosition);
                let stop = parseInt(residueDta[i].anPosition);
                let color = colorMap[residueDta[i].location];
                params.layers.push({
                    script: Script(
                          `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (in-range atom.resno ${start} ${stop} )
                          )`,
                        language
                    ),
                    color: Color("0x" + color),
                    clear: false
                });
            }

            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            await update.commit();

        } else if (annotation === "unMoleculeProcessing"){

           const residueDta = dataResidue
            .filter(residue => residue.unMoleculeProcessing.trim() !== "unk")
            .map(residue => ({
                processing: residue.unMoleculeProcessing.trim(),
                anPosition: parseInt(residue.anPosition.split("_")[1], 10)
            }));

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: ColorNames.white }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            const colorMap = {'CHAIN': '838ffc', 'SIGNAL': 'da54a3', 'TRANSIT': '820e44', 'PRO':'c746d4'};

            // Add layers to params object
            for (let i = 0; i < residueDta.length; i++) {
                let start = parseInt(residueDta[i].anPosition);
                let stop = parseInt(residueDta[i].anPosition);
                let color = colorMap[residueDta[i].processing.split("_")[0]];
                params.layers.push({
                    script: Script(
                          `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (in-range atom.resno ${start} ${stop} )
                          )`,
                        language
                    ),
                    color: Color("0x" + color),
                    clear: false
                });
            }

            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            await update.commit();

        } else if (annotation === "surface"){

             // Create and apply custom representation
            const reprParamsStructureGaussian = createStructureRepresentationParams(plugin, undefined, {
               type: "cartoon",
               color: 'accessible-surface-area',
               size: "uniform"
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureGaussian);

            await update.commit();
      } else if (annotation === "hydrophobicity"){

             // Create and apply custom representation
            const reprParamsStructureGaussian = createStructureRepresentationParams(plugin, undefined, {
               type: "cartoon",
               color: 'hydrophobicity',
               size: "uniform"
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureGaussian);

            await update.commit();
      } else if (annotation === "name"){

             // Create and apply custom representation
            const reprParamsStructureGaussian = createStructureRepresentationParams(plugin, undefined, {
               type: "cartoon",
               color: 'residue-name',
               size: "uniform"
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureGaussian);

            await update.commit();
      } else if (annotation === "rainbow"){

             // Create and apply custom representation
            const reprParamsStructureGaussian = createStructureRepresentationParams(plugin, undefined, {
               type: "cartoon",
               color: 'sequence-id',
               size: "uniform"
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureGaussian);

            await update.commit();
      }  else if (annotation === "AlphaFill"){

            const MyProvider = {
              label: (loci) => {
                if (StructureElement.Loci.is(loci)) {
                  const loc = StructureElement.Loci.getFirstLocation(loci);
                  if (!loc) return;
                  const bFactor = StructureProperties.atom.B_iso_or_equiv(loc);
                  return `pLDDT: ${bFactor}`;
                }
                return;
              },
             };

             // Create and apply custom representation
            const reprParamsStructurePlddtColor = createStructureRepresentationParams(plugin, undefined, {
                type: "cartoon",
                color: "uncertainty",
                colorParams: {
                  value: 1,
                  domain: [0, 100],
                  list: {
                    colors: [
                      Color(0x0053d6),
                      Color(0x65cbf3),
                      Color(0xffdb13),
                      Color(0xff7d45),
                      Color(0xffffff)
                    ],
                  },
                },
                size: "uniform",
            });

            const updateTheme = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructurePlddtColor);

            plugin.managers.lociLabels.addProvider(MyProvider);

            await updateTheme.commit();

            const ligand = Script.getStructureSelection(Q => Q.struct.generator.atomGroups({
                    'residue-test': Q.core.set.has([Q.set(...ligands), Q.ammp('label_comp_id')]),
                    'group-by': Q.struct.atomProperty.macromolecular.residueKey(),}), dta);

            const loci = StructureSelection.toLociWithSourceUnits(ligand);

            plugin.managers.interactivity.lociSelects.select({ loci });

            //await setSubtreeVisibility(plugin.state.data, loci, true);

        } else if (annotation === "interPro"){

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: ColorNames.white }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < domainInterPro.length; i++) {
                let start = parseInt(domainInterPro[i].start);
                let stop = parseInt(domainInterPro[i].end);
                let color = domainInterPro[i].color;

                params.layers.push({
                    script: Script(
                          `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id A )
                             :residue-test (in-range atom.resno ${start} ${stop} )
                          )`,
                        language
                    ),
                    color: Color("0x" + color),
                    clear: false
                });
            }

            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            await update.commit();

        }

      } catch (error) {
        console.error('Failed to load the PDB structure:', error);
      }
    }
  }, [uniac, dataResidue, database, annotation, colors, ligands, setLigandsOption]);

  useEffect(() => {
    initMolstar();

    return () => {
      if (pluginInstanceRef.current) {
        pluginInstanceRef.current.dispose();
        pluginInstanceRef.current = null;
      }
    };
  }, [initMolstar]);

  return <div key={uniac} ref={viewerRef} style={{ width: '100%', height: '100%' }} />;
};

export default MolstarViewerFunctional;
